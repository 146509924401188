<template>
    <Obz />
    <Head />
    <div class="aes">
        <h1>
            Установка виниров
        </h1> 
        <ul>
            <li>
                Виниры;
            </li>
            <li>
                Установка зубных украшений.
            </li>
        </ul>
        <b><img src="../assets/im3.jpg" alt="" class="leftimg"> Привлекательная улыбка - половина успеха в делах</b>
        <p>
            С помощью минимально инвазивных процедур и безболезненных методов лечения эстетическая стоматология готова прийти на помощь каждому, кто не доволен своей улыбкой.
        </p>
        <p>
            Дизайн улыбки - это различные процедуры, направленные на улучшение эстетики Вашей улыбки. Для разных людей это понятие может иметь разное значение. <br>
Некоторые стремятся к белоснежной голливудской улыбке, или к улыбке звёзд с ослепительно белыми керамическими винирами. Другие стремятся к более простым изменениям, таким как замена амальгамовых пломб на композитные или отбеливание зубов.
        </p>
        <p>
            Скученность зубов можно исправить с помощью невидимых брекетов или с помощью традиционных брекетов. Скученные зубы можно выровнить с помощью керамических виниров намного быстрее, за что их называют "мгновенной ортодонтией". В этой ситуации важным недостатком является то, что зубы необходимо обтачивать.
        </p>
        <p>
            Трещины и сколы зубов можно восстановить с помощью композитных пломб, керамических виниров и вкладок или керамических коронок.
        </p>
        <p>
            Отсутствующие зубы можно восстановить керамическими коронками с опорой на имплантаты, большие промежутки (щели ) между зубами можно восстановить с помощью композитных реставраций или керамических виниров. Ортодонтическими методами с использованием брекетов также можно устранить щели между зубами. <br>
Если у Вас выраженная линия десны,то с помощью несложного хирургического вмешательства можно устранить этот дефект.
        </p>
        <p>
            Если Вы не удовлетворены цветом Ваших зубов - отбеливание зубов к Вашим услугам.
        </p>
        <b><img src="../assets/di13.png" alt="" class="leftimg1"> Керамические виниры - Ваш идеальный выбор</b><br>
        <b>Для чего  предназначены керамические виниры? </b>
        <p>
            С помощью керамических виниров можно решить  множество проблем, связанных с формой зубов, их цветом, размером, углом наклона зубов и т.д. <br>
Именно изготовление керамических виниров является лучшим методом восстановления сколов изношенности зубов. <br>
Виниры могут быть показаны при наличие пигментированных зубов. Иногда химическое отбеливание не позволяет получить желаемый результат за короткий промежуток времени, особенно если пигментированы глубоколежащие слои зуба (дентин зуба). Кроме того, с помощью отбеливания получить заранее выбранный цвет зубов невозможно, в то время, как с помощью виниров Вы имеете возможность получить заранее предопределенный цвет зубов – определенного оттенка. Кроме того, только керамическими винирами можно исправить небольшие аномалии формы зубов. <br>
Керамическими винирами возможно изменить не только форму,  длину и угол наклона зубов, но также  закрыть небольшие промежутки между зубами. <br><br>

Не зря виниры  называют иногда мгновенной ортодонтией – за 2 или 3 визита можно исправить такие нарушения угла наклона и поворота зубов, которые с помощью ортодонтического лечения можно было бы исправить за год и более.
        </p>
        <b>
            <!-- <img src="../assets/di14.png" alt="" class="leftimg2">  -->
        Таким образом, какие шаги предстоят пациенту при протезировании керамическими винирами? </b>
        <p>
            Первым шагом является обстоятельная беседа с Вашим косметическим стоматологом. Нужно время и определенный такт и вкус, чтобы стоматолог во время общения с пациентом понял, к чему стремится пациент, понял сущность Вашей персоны, оценил, сколько времени понадобится для достижения оптимального эстетического результата. <br>
При этом важно учесть форму лица пациента, степень обнажения шеек зубов при  улыбке, цвет волос и многое другое. После подробной беседы, стоматолог готов к воплощению Ваших ожиданий  в реальность.
        </p>
        <b>Первый визит: </b>
        <p>
            Наиболее распространенный метод изготовления керамических виниров предполагает обточку поверхности  эмали передних зубов на 0,3-0,5 мм – чтобы обеспечить место для будущего винира и при этом не изменить толщину зубов после фиксации готовых виниров. Затем стоматолог снимает мерки зубов – с помощью силиконового слепочного материала – и передает их в зуботехническую лабораторию, где техники начинают изготавливать Ваши керамические виниры. После обточки зубов, их поверхность становится немного шероховатой. Чтобы защитить зуб от скапливания налета и появления пигментаций стоматолог изготовит Вам временные виниры из композитного материала. <br>
Перед фиксацией временных виниров с целью предотвращения возможной гиперчувствительности зубов необходимо нанести специальный десенситайзер, призванный защитить зуб от чувствительности и возможного проникновения микрофлоры вглубь зуба (в ткани зуба). <br>
Важно обратить внимание на состояние десен в этот период. Воспаление десен в области обточенных зубов может привести к кровоточивости десен при фиксации готовых виниров, что в свою очередь ухудшит возможность качественно зафиксировать виниры на поверхности  обточенных зубов и повлияет негативно на конечный результат. <br>
С целью передать особенности расположения, формы и цвета Ваших зубов в зуботехническую лабораторию, врач обычно делает цифровые снимки Ваших зубов и изготавливает заранее гипсовые модели зубов. <br>
До обточки зубов стоматологом, в зуботехнической лаборатории с помощью специальных восков, имитирующих цвет и текстуру зубов, техник моделирует форму будущих зубов. Только после согласования с пациентом предполагаемого результата (размера и формы виниров),  стоматолог может рассчитывать на положительный результат предпринимаемых процедур.
        </p>
        <p>
            <b>Второй визит: </b> примерка виниров.
        </p>
        <p>
            Внешний вид, форму и цвет Ваших керамических виниров можно определить во время этого визита. Стоматолог примеряет виниры в полости рта, чтобы убедиться в том, насколько точно прилегают виниры к зубам и насколько они соответствуют Вашим ожиданиям. <br>
Во время этого визита, если есть необходимость, можно внести небольшие коррективы в конструкцию винира.
        </p>
        <p>
            <b>Третий визит: </b> фиксация виниров. 
        </p>
        <p>
            Фиксация виниров может быть осуществлена в день примерки виниров в том случае, если нет необходимости вносить какие - либо изменения в форму, цвет и текстуру виниров. В противном случае этап фиксации происходит во время третьего визита. <br>
Фиксация винира предполагает соответствующую обработку поверхности винира, обращенного к зубу и обточенную поверхность зуба ортофосфорной кислотой (поверхность зуба) и плавиковой кислотой (внутренняя поверхность винира) и нанесения бонда (адгезивной системы фиксации). <br>
Затем наносится адгезивный двойного отверждевания цемент на подготовленную поверхность винира, и винир устанавливается на обточенный зуб. Далее удаляются излишки цемента и с помощью светополимиризатора  происходит окончательное отвердение цемента.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.aes {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 40vw;
}
.leftimg1 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 25vw;
}
.leftimg2 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 12vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .aes {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    .leftimg1 {
        width: 50vw;
    }
    .leftimg2 {
        width: 20vw;
    }

}
</style>