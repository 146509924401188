<template>
    <Obz />
    <Head />
    <div class="con">
        <br>
            <p class="tc">
                <b>
                ПРАВИЛА ВНУТРЕННЕГО РАСПОРЯДКА <br>
         ДЛЯ ПОСЕТИТЕЛЕЙ И ПАЦИЕНТОВ ООО "БЬЮТИФУЛ ДЕНТ" <br><br><br>    
        </b>
        </p>
        <p>
            Настоящие Правила внутреннего распорядка (далее по тексту - Правила) определяют внутренний распорядок в медицинской организации, режим работы, порядок обращения пациентов, права и обязанности пациентов, порядок оказания медицинских услуг, порядок оформления медицинских документов. Настоящие Правила разработаны в соответствии c Федеральным законом № 323 "Об основах охраны здоровья граждан в Российской Федерации", законом "О защите прав потребителей", Гражданским кодексом Российской Федерации, Правилами предоставления медицинскими организациями платных медицинских услуг, утвержденных Пост. Правительства РФ от 04.10.2012 г. №1006, Приказом Минздрава России от 29.06.2016 г. №425н «Об утверждении порядка ознакомления пациента либо его законного представителя с медицинской документацией, отражающей состояние здоровья пациента». <br>

 

1. ОБЩИЕ ПОЛОЖЕНИЯ<br>

1.1. ООО "Бьютифул Дент" осуществляет доврачебную медицинскую помощь по: сестринскому делу, стоматологии, стоматологии профилактической; первичную специализированную медицинскую помощь по: стоматологии ортопедической, стоматологии общей практики, стоматологии терапевтической, стоматологии ортодонтической, стоматологии хирургической  согласно лицензии Л041-011-91-21/00310886  от 29.12.2016 г. выданную Министерством Здравоохранения Чувашской Республики.<br>

1.2. Лечащими врачами, оказывающими медицинскую помощь пациенту в период его наблюдения и лечения в ООО "Бьютифул Дент", являются: врач-стоматолог, врач стоматолог-ортопед, врач стоматолог-терапевт,  врач-ортодонт, врач стоматолог-хирург в зависимости от причины обращения пациента за медицинской помощью.<br>

Лечащие врачи организуют своевременное и квалифицированное обследование и лечение пациента, предоставляют информацию о состоянии его здоровья, в необходимых случаях направляют на консультации к врачам-специалистам .<br>

1.3. Медицинские услуги оказываются пациентам ООО "Бьютифул Дент" на платной основе.<br>

 

2. РЕЖИМ РАБОТЫ <br>

2.1.  ООО "Бьютифул Дент" работает:<br>

с 9.00 час. до 19.00 час.  понедельник-пятница;<br>

с 9.00 час. до 15.00 час.  суббота-воскресенье - по предварительной записи;<br>

четверг выходной.<br>

в праздничные дни режим работы регламентируется приказом главного врача.<br>

2.2. Приём врачей всех специальностей осуществляется по скользящему графику.<br>

Информация о фамилии, имени, отчестве, специальности, квалификации, графике работы специалистов размещается на информационном стенде/стойке  организации, а также на  сайте ООО "Бьютифул Дент" в информационно-телекоммуникационной сети "Интернет".<br>

 

3. ПОРЯДОК ОБРАЩЕНИЯ ПАЦИЕНТОВ<br>

3.1. Прием пациентов на консультацию, первичный осмотр и лечение осуществляется по предварительной записи в регистратуре клиники непосредственно или по тел.: 8(927)667-55-05.<br>

День и время приёма пациент выбирает из имеющихся свободных по согласованию с администратором.<br>

            Пациент может получить предварительную информацию об оказываемых услугах, их стоимости, порядке оплаты и т.д. в регистратуре непосредственно или по указанным выше телефонам.<br>

3.2.Учитывая, что основным документом, удостоверяющим личность на территории Российской Федерации является паспорт гражданина РФ (в соответствии с Указом Президента РФ от 13 марта 1997 г. № 232 "Об основном документе, удостоверяющем личность гражданина РФ на территории РФ" и Постановлением Правительства РФ от 08 июля 1997 г. № 828 "О паспорте гражданина РФ, образца бланка и описания паспорта гражданина РФ"), оказание медицинских услуг в ООО "Бьютифул Дент" пациенту без предъявления паспорта (или документа, его заменяющего) невозможен.<br>

3.3. Амбулаторно-поликлиническая помощь при состояниях, требующих срочного медицинского вмешательства (кровотечение, травма, другие состояния, угрожающие жизни или здоровью гражданина) предоставляется в момент обращения, независимо от места проживания и наличия документов, вне очереди и без предварительной записи.<br>

3.4. Пациенты с острой болью принимаются без предварительной записи в день обращения при наличии свободного времени у врача.<br>

3.5.В случае опоздания более чем на 10 минут или невозможности явиться на прием пациент обязан предупредить об этом администратора по телефону (или другим возможным способом) не менее чем за 24 часа до приема. При повторной неявке на приём пациенту может быть отказано в оказании услуг или приём перенесен на другое время.<br>

3.6. Если в назначенный день прием пациента невозможен (по причине внепланового отпуска, болезни врача, или по иному основанию, не зависящему от пациента), пациент уведомляется об этом в кратчайшие сроки любым доступным способом (непосредственно при личном обращении в клинику, при предоставлении информации: по телефону).<br>

3.7. Повторный приём пациента осуществляется в день и время, назначенное врачом. Неявка на приём в назначенный день считается нарушением поликлинического режима.<br>

3.8. В стенах учреждения видео- и аудиозаписи запрещены.<br>

3.9. Вне очереди обслуживаются: Герои Советского Союза, кавалеры трех орденов Славы, инвалиды и участники Великой Отечественной войны, Почетные доноры России и СССР, ветераны здравоохранения.<br>

 

4. ПРАВА ПАЦИЕНТА<br>

4.1. Пациент имеет право на:<br>

4.1.1. уважительное и гуманное отношение со стороны медицинского и обслуживающего персонала;<br>

4.1.2. квалифицированное оказание платной медицинской помощи со стороны ООО "Бьютифул Дент";<br>

4.1.3. обследование, лечение и содержание в условиях, соответствующих санитарно-гигиеническим требованиям;<br>

4.1.4. проведение по его просьбе консилиума;<br>

4.1.5 облегчение боли, связанной с заболеванием и (или) медицинским вмешательством, доступными способами и средствами;<br>

4.1.6 сохранение в тайне информации о факте обращения за медицинской помощью, о состоянии здоровья, диагнозе и иных сведений, полученных при его обследовании и лечении, кроме случаев, предусмотренных законом;<br>

4.1.7 информированное добровольное согласие на медицинское вмешательство;<br>

4.1.8. отказ от медицинского вмешательства;<br>

4.1.9. получение информации о своих правах и обязанностях и о состоянии своего здоровья;<br>

4.1.10. возмещение ущерба в случае причинения вреда его здоровью при оказании медицинской стоматологической помощи;<br>

4.1.11.другие права, предусмотренные действующим законодательством РФ.<br>

 

5. ОБЯЗАННОСТИ ПАЦИЕНТА<br>

5.1. Пациент обязан:<br>

5.1.1. выполнять настоящие правила внутреннего распорядка;<br>

5.1.2. выполнять предписания лечащего врача;<br>

5.1.3. выполнять условия заключенного между ним и ООО "Бьютифул Дент" договора на оказание платных медицинских услуг;<br>

5.1.4. соблюдать правила гигиены, санитарные нормы нахождения в медицинском учреждении, правила пожарной и террористической безопасности, правила запрета курения в медицинских учреждениях;<br>

5.1.5. уважительно относиться к другим пациентам и посетителям ООО "Бьютифул Дент", к медицинскому и обслуживающему персоналу медицинской организации, соблюдать общепринятые правила этики и поведения.<br>

 

 

6. ПОРЯДОК ОФОРМЛЕНИЯ МЕДИЦИНСКИХ ДОКУМЕНТОВ<br>

6.1. Основными медицинскими документами пациента является медицинская карта стоматологического больного (форма № 043/У), договор (с приложениями) на оказание платных медицинских услуг и информированное согласие.<br>

6.2.Медицинская карта  больного хранится не более 25 лет с момента последнего обращения пациента.<br>

6.3.Медицинская документация, оформляемая при обращении пациента в ООО "Бьютифул Дент" является собственностью организации. Пациент имеет право непосредственно знакомиться с медицинской документацией, отражающей состояние его здоровья, в специально отведённом для этого месте в присутствии представителя ООО "Бьютифул Дент", получать консультации по ней у других специалистов. По требованию пациента ему предоставляются копии медицинских документов, отражающих состояние его здоровья. Выдача копий и ознакомление организовывается на основании письменного заявления, согласованного главным врачом, заместителем главного врача, либо заведующим отделением. Не допускается передача первичной медицинской документации на руки пациентам.<br>

6.4.Хранение медицинской карты больного на дому, передача её в другие лечебные учреждения, третьим лицам запрещается, кроме случаев, предусмотренных законом.<br>

Порядок оформления документов регламентируется ФЗ № 323 от 21.11.2011г. "Об основах охраны здоровья граждан", приказами и инструкциями Минздрава РФ.<br>

 

7. ПРЕДОСТАВЛЕНИЕ ИНФОРМАЦИИ<br>

7.1. Информация о предоставлении услуг представлена:<br>

на информационном стенде/стойке медицинской организации и на сайте медицинской организации в информационно-телекоммуникационной сети "Интернет".<br>

7.2. Информация о состоянии здоровья гражданина, о факте обращения его за медицинской помощью является конфиденциальной и защищена законом (ст. 13 ФЗ №323 от 21.11.2011г., "Об основах охраны здоровья граждан").<br>

7.3. Информация о состоянии здоровья гражданина может быть предоставлена в правоохранительные органы, органы дознания на основании письменного запроса и в случаях, предусмотренных законодательством.<br>

7.4. Информация о состоянии здоровья предоставляется пациенту устно, лечащим врачом или иными должностными лицами ООО «Бьютифул Дент» в доступной для него форме с учетом основных принципов этики и деонтологии. Она должна содержать сведения о результатах обследования, наличии заболевания, диагнозе и прогнозе, методах обследования и лечения, связанном с ними риске, возможных вариантах медицинского вмешательства и их последствиях, а также о результатах проведённого лечения и возможных осложнениях.<br>

Информация о состоянии здоровья пациента сообщается членам его семьи, если пациент в письменной форме дал разрешение о предоставлении таковых сведений членам его семьи.<br>

7.5. В отношении несовершеннолетних и лиц, признанных в установленном законом порядке недееспособными, информация о состоянии здоровья пациента предоставляется их законному представителю<br>

7.6. В случае отказа пациента от получения информации о состоянии своего здоровья делается соответствующая запись в медицинской документации.<br>

7.7. Информация, содержащаяся в медицинской документации, составляет врачебную тайну и может предоставляться без согласия пациента только по основаниям, предусмотренным действующим законодательством.<br>

7.8. Медицинская документация, оформляемая при обращении пациента в ООО «Бьютифул Дент» является собственностью организации. Пациент имеет право непосредственно знакомиться с медицинской документацией, отражающей состояние его здоровья, в специально отведённом для этого месте – в кабинете руководителя в присутствии представителя ООО «Бьютифул Дент», получать консультации по ней у других специалистов.<br>

Для этого пациенту необходимо:<br>

оформить письменное заявление о возможности ознакомления с медицинской документацией – амбулаторной картой.<br>

Согласовать дату и время ознакомления (администратор оформляет «Журнал учета предварительной записи посещений помещения для ознакомления с медицинской документацией») согласно графику работы медицинской организации и графику работы помещения (места) для ознакомления с медицинской документацией.<br>

 

По требованию пациента ему предоставляются копии медицинских документов, отражающих состояние его здоровья. Выдача копий и ознакомление организовывается на основании письменного заявления, согласованного главным врачом, заместителем главного врача.<br>

 

8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ<br>

8.1.Все обращения граждан (при возникновении конфликтных или сложных ситуаций и вопросов) рассматриваются в соответствии с Положением о порядке рассмотрения обращений граждан в ООО "Бьютифул Дент". <br>

8.2. На правоотношения пациентов распространяется действие закона "О защите прав потребителей"<br>

8.3. Вся информация о работе ООО "Бьютифул Дент", о контролирующих органах, о гарантийных сроках и т.п. доводится до сведения пациентов в доступной форме на информационных стендах/стойках, расположенных, в здании медицинской организации и на сайте медицинской организации в информационно-телекоммуникационной сети "Интернет".<br>


        </p>
            <br><br><br>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.con {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
.tc {
    text-align: center;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .con {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }

}
</style>