<template>
    <Obz />
    <Head />
    <div class="list">
        <h1>О возможности получения бесплатной медицинской помощи</h1>
        <p>
            В соответствии с Федеральным законом от 21.11.2011 № 323-ФЗ «Об основах охраны 
здоровья граждан в Российской Федерации» каждый имеет право на медицинскую 
помощь в гарантированном объеме, оказываемую без взимания платы в соответствии с 
программой государственных гарантий бесплатного оказания гражданам медицинской 
помощи. <br>
Бесплатная медицинская помощь в сфере обязательного медицинского страхования 
оказывается в медицинских организациях, участвующих в реализации программы 
государственных гарантий оказания бесплатной медицинской помощи,  включенных в 
реестр медицинских организаций, осуществляющих деятельность в сфере обязательного 
медицинского страхования. <br>
ООО «Бьютифул Дент» в реализации Программы государственных гарантий бесплатного 
оказания гражданам в Чувашской Республике медицинской помощи, Территориальной 
программы обязательного медицинского страхования граждан в Чувашской Республике 
не участвует.
        </p>
        <br><br>
        <h1>Полезные ссылки</h1>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights" target="_blank" rel="noopener">
                Права и обязанности
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/poryadok-okazaniya-i-standarti-medicinskoj-pomosch" target="_blank" rel="noopener">
                Маршруты, порядки, стандарты оказания медицинской помощи, клинические рекомендации
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/056928562" target="_blank" rel="noopener">
                Критерии доступности и качества медицинской помощи, оказываемой в рамках 
Программы государственных гарантий бесплатного оказания гражданам в 
Чувашской Республике медицинской помощи 
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/056928562" target="_blank" rel="noopener">
                О порядке и условиях предоставления медицинской помощи в соответствии с 
программой государственных гарантий бесплатного оказания медицинской 
помощи
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/monitoring-assortimenta-i-cen-na-zhnvlp" target="_blank" rel="noopener">
                Перечень жизненно необходимых и важнейших лекарственных препаратов для 
медицинского применения (с изменениями и дополнениями). 
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/056928562" target="_blank" rel="noopener">
                Перечень лекарственных препаратов, отпускаемых населению в соответствии с 
перечнем групп населения и категорий заболеваний, при амбулаторном лечении 
которых лекарственные препараты и медицинские изделия отпускаются по 
рецептам врачей бесплатно, а также в соответствии с перечнем групп населения, 
при амбулаторном лечении которых лекарственные препараты отпускаются по 
рецептам врачей с 50-процентной скидкой 
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/056928562" target="_blank" rel="noopener">
                Перечень медицинских организаций, участвующих в реализации Программы 
государственных гарантий бесплатного оказания 
гражданам в Чувашской Республике  медицинской помощи, в том числе 
Территориальной программы обязательного медицинского страхования граждан в 
Чувашской Республике, и медицинских организаций, проводящих 
профилактические медицинские осмотры и диспансеризацию, в том числе 
углубленную диспансеризацию
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/056928562" target="_blank" rel="noopener">
                Программа государственных гарантий бесплатного оказания гражданам 
медицинской помощи в Чувашской Республике 
            </a>
        </p>
        <p>
            <a href="https://www.med.cap.ru/pacientam/patient-rights/86467874678" target="_blank" rel="noopener">
                Программа государственных гарантий бесплатного оказания гражданам 
медицинской помощи в Российской Федерации 
            </a>
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.list {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}
a {
    /* font-size: 1vw; */
    color: #018F3E;
}
a:hover {
    color: #333333;
}

@media (max-width: 790px) {
    .list {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
        margin-bottom: 5vh;
    }
    p {
        font-size: 14px;
    }
}
</style>