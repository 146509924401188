<template>
    <div>
        <div class="imim">
            <img alt="" src="../assets/im1.png">
        </div>
        <div class="tt1">
            <h1>
                Профессиональное лечение,<br>
                протезирование <br>
                и имплантация зубов <br>
                в клинике Beautiful Dent
            </h1>
            <h2>
                Создаем идеальные улыбки уже 10 лет
            </h2>
            <button type="button" class="biz" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Записаться на консультацию    
            </button>
        </div>
        <Modal />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    name: 'Glav',
    components: {
        Modal,
    }
}
</script>

<style scoped>
.imim {
    display: flex;
    justify-content: flex-end;
}
img {
    width: 45%;
}
.tt1 {
    margin-left: 8vw;
    margin-top: -50vh;
}
button {
    width: 20vw;
    height: 6vh;
    margin-top: 2vh;
    background-color: #018F3E;
    border: #018F3E;
    color: white;
    border-radius: 50px;
    font-size: 1vw;
    transition: all 0.4s ease;
}
button:hover {
  transform: scale(1.03);
  transition: all 0.4s ease;
}
h1 {
    font-size: 2.5vw;
}
h2 {
    font-size: 1.5vw;
}

@media (max-width: 790px) {

    .tt1 {
        margin-left: 4vw;
        margin-top: -27%;
    }
    img {
        width: 100%;
    }
    h1 {
        font-size: 22px;
        margin-top: 19vh;
    }
    h2 {
        font-size: 16px;
        margin-top: 4vh;
    }
    button {
        width: 92vw;
        height: 6vh;
        margin-top: 3vh;
        background-color: #018F3E;
        border: #018F3E;
        color: white;
        border-radius: 50px;
        font-size: 14px;
        transition: all 0.4s ease;
    }
}
</style>