<template>
    <h1 id="f4">Контакты</h1>
    <div class="cont">
        <div class="lef">
            <h2>
                Стоматологическая клиника ООО "Бьютифул Дент"
            </h2>
            <p>
                <b>Адрес:</b> 428022, г. Чебоксары, ул. Гагарина, д.30, корп.1, пом.3 <br>

ост. "гостиница Россия" или "Агрегатный завод"
            </p>
            <p>
                <b>Email:</b> <a class="em" href="mailto:beautiful-dent@mail.ru">beautiful-dent@mail.ru</a>
            </p>
            <p>
                <b>Телефон:</b> <a href="tel:+79276675505">8 (927) 667-55-05</a>
            </p>
            <p>
                <b>Режим работы:</b> ПН–ПТ 9:00-19:00 <br>
СБ-ВС по записи 9:00-15:00 <br>
ЧТ выходной
            </p>
            <div class="vv">
                <!-- <a href="viber://chat?number=%2B9276675505"><img class="vib" alt="" src="../assets/vib.png" /></a> -->
                <a
                    href="https://api.whatsapp.com/send?phone=79276675505"
                    target="_blank"
                    rel="noopener"
                >
                    <img class="wal" alt="" src="../assets/wal.png" />
                </a>
                <a href="https://www.instagram.com/nadi_nok"><img class="ins" alt="" src="../assets/ins.png" /></a>
                <!-- <a href="https://vk.com/club143692167" target="_blank" rel="noopener">
                    <img class='vk' alt="" src="../assets/vk.jpg"/>
                </a> -->
            </div>
        </div>
        <div class="rig">
            <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/beautiful_dent/132155759262/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Beautiful dent</a><a href="https://yandex.ru/maps/45/cheboksary/category/dental_clinics/184106132/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Стоматологическая клиника в Чебоксарах</a><iframe src="https://yandex.ru/map-widget/v1/?ll=47.272093%2C56.125987&mode=search&oid=132155759262&ol=biz&z=15.64" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.cont {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
    margin: 5vh auto;
    height: 83vh;
}
.lef {
    margin-left: 8vw;
}
.rig {
    margin-right: 7vw;
}
iframe {
    width: 42vw;
    height: 80vh;
}
h1 {
    font-size: 2vw;
    color: #018F3E;
    margin-top: 53vh;
    text-align: center;
    font-weight: bold;
}
h2 {
    font-size: 1.4vw;
}
p {
    font-size: 1.2vw;
}
a {
  color: #018F3E;
}
.vib {
    width: 2vw;
    margin-right: 2vw;
}
.wal {
    width: 2.5vw;
    margin-right: 2vw;
}
.ins {
    width: 2vw;
    margin-right: 2vw;
}
.vk {
    width: 2vw;
}

@media (max-width: 790px) {

    .cont {
        grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
        margin: 0vh auto;
        height: 140vh;
    }
    .lef {
        margin-left: 3vw;
    }
    .rig {
        margin-left: 7vw;
    }
    h1 {
        font-size: 27px;
        margin-top: 47vh;
    }
    h2 {
        font-size: 17px;
        width: 70vw;
    }
    p {
        font-size: 14px;
    }
    .vib {
        width: 40px;
        margin-right: 2vw;
    }
    .wal {
        width: 50px;
        margin-right: 2vw;
    }
    .ins {
        width: 40px;
        margin-right: 2vw;
    }
    iframe {
        width: 87vw;
        height: 80vh;
    }
}
</style>