<template>
  <div class="car">
    <h1>Специальные предложения</h1>
    <a-carousel autoplay arrows effect="fade">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined />
        </div>
      </template>
      <div><img class='ii' alt="" src="../assets/ak1.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/ak2.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/ak3.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/ak4.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/ak5.jpg" /></div>
    </a-carousel>
  </div>
</template>

<script>
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    ACarousel: Carousel,
    LeftCircleOutlined,
    RightCircleOutlined,
    CloseOutlined,
  }
}
</script>

<style scoped>
.car {
  margin: 0 auto;
}
h1 {
  font-size: 2vw;
  text-align: center;
  color: #018F3E;
  font-weight: bold;
}
.ii {
  margin-top: 5vh;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  object-fit: cover;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 80vh;
  line-height: 160px;
  overflow: hidden;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3.3vw;
  height: 3.3vw;
  font-size: 7vh;
  color: #a09e9e;
  background-color: rgba(252, 253, 253, 0.11);
  opacity: 0.4;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.7;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

@media (max-width: 790px) {

  h1 {
    font-size: 27px;
    margin-top: 9vh;
  }
  .ii {
    width: 100%;
    height: auto;
  }
  .ant-carousel :deep(.slick-slide) {
    height: auto;
    margin-top: -9vh;
  }
  .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    margin-right: 32px;
    margin-top: -6vh;
  }

}
</style>