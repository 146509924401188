<template>
    <div class="ser">
        <!-- <div class="mr"></div> -->
        <h1 id="f1">
            Наши услуги
        </h1>
        <div class="cen">
            <router-link to="/therapy">
                <div class="pr">
                    <img src="../assets/den1.png" alt="">
                    <h5>
                        Лечение <br> зубов
                    </h5>
                    <h4>
                        от 1 800 руб
                    </h4>
                </div>
            </router-link>
            <router-link to="/orthopedics">
                <div class="pr">
                    <img src="../assets/den2.png" alt="">
                    <h5>
                        Протезирование <br> зубов
                    </h5>
                    <h4>
                        от 4 000 руб
                    </h4>
                </div>
            </router-link>
            <router-link to="/orthodontics">
                <div class="pr">
                    <img src="../assets/den3.png" alt="">
                    <h5>
                        Установка <br> брекетов
                    </h5>
                    <h4>
                        от 3 000 руб
                    </h4>
                </div>
            </router-link>
            <router-link to="/aligners">
                <div class="pr">
                    <img src="../assets/den19.png" alt="">
                    <h5>
                        Установка <br> элайнеров
                    </h5>
                    <h4>
                        от 12 000 руб
                    </h4>
                </div>
            </router-link>
            <router-link to="/implantation">
                <div class="pr">
                    <img src="../assets/den4.png" alt="">
                    <h5>
                        Имплантация <br> зубов
                    </h5>
                    <h4>
                        от 26 500 руб
                    </h4>
                </div>
            </router-link>
            <router-link to="/aesthetic">
                <div class="pr">
                    <img src="../assets/den5.png" alt="">
                    <h5>
                        Установка <br> виниров
                    </h5>
                    <h4>
                        от 3 500 руб
                    </h4>
                </div>
            </router-link>
            <router-link to="/vncs">
                <div class="pr">
                    <img src="../assets/den7.png" alt="">
                    <h5>
                        Лечение дисфункции <br> ВНЧ сустава 
                    </h5>
                    <h4>
                        от 2 000 руб
                    </h4>
                </div>
            </router-link>
        </div>
        <div class="btt">
            <router-link to="/price">
                <button>
                    Посмотреть все услуги
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.ser {
    width: 82vw;
    margin: 0 auto;
    height: 60vh;
}
/* .mr {
    height: 16vh;
} */
h1 {
    font-size: 2vw;
    color: #018F3E;
    text-align: center;
    font-weight: bold;
}
.cen {
    display: flex;
    justify-content: space-between;
    margin-top: 8vh;
}
.pr {
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease;
   
}
.pr:hover {
    transform: scale(1.03);
    transition: all 0.4s ease;
}
img {
    margin: 0 auto;
    width: 5vw;
    height: 5vw;
}
h5 {
    font-size: 1.3vw;
    font-weight: bold;
    margin-top: 3vh;
    text-align: center;
}
h4 {
    font-size: 1.2vw;
    margin: 0 auto;
}
.btt {
    display: flex;
    justify-content: center;
}
button {
    width: 20vw;
    height: 6vh;
    margin-top: 7vh;
    background-color: #018F3E;
    border: #018F3E;
    color: white;
    border-radius: 50px;
    font-size: 1vw;
    transition: all 0.4s ease;
}
button:hover {
    transform: scale(1.03);
    transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .ser {
        width: 95vw;
        height: auto;
        margin-top: 15vh;
    }
    /* .mr {
        height: 0vh;
    } */
    .cen {
        display: flex;
        flex-direction: column;
        margin-top: 0vh;
    }
    h1 {
        font-size: 27px;
        margin-bottom: 7vh;
        margin-left: 1vw;
        margin-top: -5vh;
    }
    .pr {
        width: 93vw;
        border-radius: 7px;
        margin-bottom: 4vh;
    }
    img {
        width: 80px;
        height: 80px;
    }
    h4 {
        font-size: 22px;
    }
    h5 {
        font-size: 22px;
    }
    button {
        margin-top: 2vh;
        width: 93vw;
        font-size: 14px;
    }
    
}
</style>