<template>
  <div class="mrig">
    <a @click="$router.go(-1)"><button class="bm"><close-outlined /></button></a>  
  </div>
  <div class="gal">
    <a-carousel autoplay arrows effect="fade">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined />
        </div>
      </template>
      <div><img class='ii' alt="" src="../assets/im28.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im3.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im31.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im4.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im26.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im27.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im5.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im6.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im29.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im7.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im30.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im8.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im32.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im10.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im33.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im20.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im34.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im22.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im35.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im23.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im24.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im39.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im25.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im40.jpg" /></div>
      <div><img class='ii' alt="" src="../assets/im41.jpg" /></div>
    </a-carousel>
  </div>
  <div class="mr"></div>
</template>

<script>
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
  name: 'GalleryView',
  components: {
    ACarousel: Carousel,
    LeftCircleOutlined,
    RightCircleOutlined,
    CloseOutlined,
  }
}
</script>

<style scoped>
.mrig {
  display: flex;
  justify-content: flex-end;
}
.gal {
  width: 80vw;
  margin: 5vh auto;
}
.bm {
  transform: scale(2);
  color: #333333;
  border: #333333;
  background: #fff;
  margin-right: 3vw;
  margin-top: 3vh;
}
.ii {
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: auto;
  line-height: 160px;
  overflow: hidden;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3.3vw;
  height: 3.3vw;
  font-size: 7vh;
  color: #fff;
  background-color: rgba(252, 253, 253, 0.11);
  opacity: 0.7;
  z-index: 1;
  margin-top: -70vh;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.7;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.mr {
  height: 10vh;
}
</style>