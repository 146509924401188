<template>
  <Obz />
  <Head />
  <div class="about">
    <h1>Документы клиники</h1>
    <div class="dd">
      <img src="../assets/im12.jpg" alt="">
      <img src="../assets/im13.jpg" alt="">
    </div>
    <div class="dd">
      <img src="../assets/im14.jpg" alt="">
      <img src="../assets/im15.jpg" alt="">
    </div>
    <div class="dd">
      <img src="../assets/im18.jpg" alt="">
      <img src="../assets/im16.jpg" alt="">
    </div>
    <div class="dd">
      <img src="../assets/im19.jpg" alt="">
      <img src="../assets/im17.jpg" alt="">
    </div>
  </div>
  <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
  name: 'AboutView',
  components: {
    Head,
    Foo,
    Obz,
  }
}
</script>
<style scoped>
.about {
  width: 80vw;
  margin: 7vh auto;
  height: auto;
}
.dd {
  display: flex;
}
h1 {
  font-size: 2vw;
}
h2 {
  font-size: 1.2vw;
}
h3 {
  font-size: 1.7vw;
}
p {
  font-size: 1vw;
}

button {
  width: 25vw;
  height: 6vh;
  margin-top: 3vh;
  background-color: #018F3E;
  border: #018F3E;
  color: white;
  border-radius: 50px;
  font-size: 1vw;
  transition: all 0.4s ease;
}
button:hover {
  transform: scale(1.03);
  transition: all 0.4s ease;
}

@media (max-width: 790px) {

  .about {
    width: 90vw;
    margin: 0vh auto;
  }
  h1 {
    font-size: 19px;
  }
  .dd {
    display: block;
  }
  img {
    width: 92vw;
  }
  h2 {
    font-size: 17px;
  }
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }

}
</style>

