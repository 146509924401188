<template>
    <Obz />
    <Head />
    <div class="wr">
        <h1><img src="../assets/doc2.jpg" alt="" class="leftimg"> Киртаева Анастасия Владиславовна</h1>
        <h2>Директор и главный врач</h2>
        <h2>Врач-стоматолог-ортопед <br> Врач-стоматолог-терапевт</h2>
        <h2>Кандидат медицинских наук</h2>
        <p>
            Ведущий специалист <br>
            Стаж более 20 лет <br>
            <b>Диплом об образовании:</b> <br> ВСБ № 0563213 от 30.06.2003 г «ЧГУ им. И.Н.Ульянова» г.Чебоксары специальность «Стоматология» <br>
            <b>Сертификат (стоматология терапевтическая):</b> <br> №0121242094868 ФГБОУ ВО "ЧГУ им. И.Н. Ульянова" от 17.02.2020 г. <br>
            <b>Сертификат (стоматология ортопедическая):</b> <br> №012241560917 ФГБОУ ВО "ЧГУ им. И.Н. Ульянова" от 29.12.2018 г.
        </p>
        <p>
            Прием по согласованию - по записи
        </p>
        <p>
            График личного приема руководства: <br>
            среда с 15-00 до 16-00 ч
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue' 
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.wr {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
h2 {
    font-size: 1.2vw;
}
p {
    font-size: 1vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 3vw 0; /* Отступы вокруг картинки */
    width: 17vw;
}

@media (max-width: 790px) {

    .wr {
        width: 80vw;
        height: auto;
        margin-top: 0;
    }
    .leftimg {
        width: 80vw;
        margin-bottom: 7vh;
    }
    h1 {
        font-size: 19px;
    }
    h2 {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }

}
</style>