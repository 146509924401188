<template>
    <Obz />
    <Head />
    <div class="con">
        <h1>
            Налоговые вычеты
        </h1> 
        <p>
            <b>Справка из стоматологии на налоговый вычет.</b><br><br>
            Чтобы получить справку в нашей стоматологии для предъявления в налоговую инспекцию на налоговый вычет, Вам необходимо оставить заявку в регистратуре клиники:<br>

1) предъявить договор,чек и гарантийный талон или квитанции-бланки строгой отчётности, выписанные в нашей стоматологической клинике;<br>

2) сообщить свой номер ИНН (если есть).<br>

Срок изготовления справки – 1 день!<br>
        </p>
        <p>
            <b>Документы, регламентирующие порядок выписки справки в налоговую инспекцию для налогового вычета пациентам:</b><br><br>
            ПОСТАНОВЛЕНИЕ от 19 марта 2001 г. N 201 ОБ УТВЕРЖДЕНИИ ПЕРЕЧНЕЙ МЕДИЦИНСКИХ УСЛУГ И ДОРОГОСТОЯЩИХ ВИДОВ ЛЕЧЕНИЯ В МЕДИЦИНСКИХ УЧРЕЖДЕНИЯХ РОССИЙСКОЙ ФЕДЕРАЦИИ, ЛЕКАРСТВЕННЫХ СРЕДСТВ, СУММЫ ОПЛАТЫ КОТОРЫХ ЗА СЧЕТ СОБСТВЕННЫХ СРЕДСТВ НАЛОГОПЛАТЕЛЬЩИКА УЧИТЫВАЮТСЯ ПРИ ОПРЕДЕЛЕНИИ СУММЫ СОЦИАЛЬНОГО НАЛОГОВОГО ВЫЧЕТА (в ред. Постановления Правительства РФ от 26.06.2007 N 411) <br>

МИНИСТЕРСТВО РОССИЙСКОЙ ФЕДЕРАЦИИ ПО НАЛОГАМ И СБОРАМ N БГ-3-04/256 ПРИКАЗ от 25 июля 2001 года О РЕАЛИЗАЦИИ ПОСТАНОВЛЕНИЯ ПРАВИТЕЛЬСТВА РОССИЙСКОЙ ФЕДЕРАЦИИ ОТ 19 МАРТА 2001 Г. N 201 "ОБ УТВЕРЖДЕНИИ ПЕРЕЧНЕЙ МЕДИЦИНСКИХ УСЛУГ И ДОРОГОСТОЯЩИХ ВИДОВ ЛЕЧЕНИЯ В МЕДИЦИНСКИХ УЧРЕЖДЕНИЯХ РОССИЙСКОЙ ФЕДЕРАЦИИ, ЛЕКАРСТВЕННЫХ СРЕДСТВ, СУММЫ ОПЛАТЫ КОТОРЫХ ЗА СЧЕТ СОБСТВЕННЫХ СРЕДСТВ НАЛОГОПЛАТЕЛЬЩИКА УЧИТЫВАЮТСЯ ПРИ ОПРЕДЕЛЕНИИ СУММЫ СОЦИАЛЬНОГО НАЛОГОВОГО ВЫЧЕТА"<br>

МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ И СОЦИАЛЬНОГО РАЗВИТИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ ПИСЬМО от 7 ноября 2006г. № 26949/МЗ-14<br>

ФЕДЕРАЛЬНАЯ НАЛОГОВАЯ СЛУЖБА ПИСЬМО от 25 декабря 2006г. № 04-2-05/7.<br>
        </p>
            <br><br><br>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.con {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
.tc {
    text-align: center;
}
h1 {
    font-size: 2vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .con {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }

}
</style>