<template>
    <div class="im">
        <div class="imcen">
            <router-link to="/im2">
                <img src="../assets/im4.jpg" class="rounded float-start" alt="">
            </router-link>
            <router-link to="/im3">
                <img src="../assets/im20.jpg" class="rounded im1" alt="">
            </router-link>
            <router-link to="/im4">
                <img src="../assets/im28.jpg" class="rounded im2" alt="">
            </router-link>
            <router-link to="/im5">
                <img src="../assets/im10.jpg" class="rounded float-end" alt="">
            </router-link>
        </div>
        <div class="imbot">
            <router-link to="/im6">
                <img src="../assets/im6.jpg" class="rounded float-start" alt="">
            </router-link>
            <router-link to="/im7">
                <img src="../assets/im7.jpg" class="rounded im1" alt="">
            </router-link>
            <router-link to="/im8">
                <img src="../assets/im8.jpg" class="rounded im2" alt="">
            </router-link>
            <router-link to="/im9">
                <img src="../assets/im5.jpg" class="rounded float-end" alt="">
                
            </router-link>
        </div>
        <div class="ga">
            <router-link to="/gallery">
                <h4>
                    Посмотреть все фото
                </h4>
            </router-link>
        </div>
    </div>
</template>

<script>

export default {

}
</script>

<style scoped>
.im {
    height: 50vh;
    width: 82vw;
    margin: 27vh auto;
}
.rounded {
    width: 20vw;
    margin-bottom: 0.65vw;
}
.im1,
.im2 {
    margin-left: 0.65vw;
}
.ga {
    margin-left: 8.3vw;
    margin-top: 3vh;
    width: 12vw;
}
h4 {
    font-size: 1vw;
    color: #018F3E;
    transition: all 0.4s ease;
}
h4:hover {
    color: #000;
    transition: all 0.4s ease;
}
img {
    transition: all 0.4s ease;
}
img:hover {
    transform: scale(1.03);
    transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .im {
        height: 5vh;
        margin-top: 10vh;
    }
    .ga {
        width: 50vw;
    }
    h4 {
        font-size: 12px;
    }
}
</style>