<template>
    <Obz />
    <Head />
    <div class="ortd">
        <h1>
            Элайнеры
        </h1> 
        <p>
            <b>
                <!-- <img src="../assets/im37.jpg" alt="" class="leftimg1"> -->
                Что такое элайнеры и для чего их ставят на зубы?</b> <br>
            Это абсолютно прозрачные ортодонтические конструкции (каппы) для исправления различных дефектов прикуса. Другими словами, это незаметная постороннему глазу частичная альтернатива популярным брекет-системам. При этом лечение элайнерами рекомендовано в случае легкой или средней степени тяжести. <br>
            <b>Как работают элайнеры?</b> <br>
            Чтобы зубы выровнялись и заняли правильное положение в рядах, используется несколько наборов элайнеров (так называемых капп). Каждое изделие в наборе проектируется таким образом, чтобы оказывать определенную степень давления на зубы и приводить к их постепенному смещению. Как только зубы примут ту позицию, которая была предусмотрена конкретной моделью, ее меняют на новую. <br>
            <b>Как долго носят элайнеры?</b> <br>
            Сроки лечения зависят от тяжести патологии прикуса. В легких случаях на коррекцию уходит 8-10 месяцев, тяжелые аномалии потребуют ношения элайнеров в течение 1-1,5 лет. <br>
        </p>
        <p>
            <b>Плюсы элайнеров:</b>
        </p>
        <ul>
            <li>Незаметность;</li>
            <li>Простой уход;</li>
            <li>Комфорт;</li>
            <li>Нет ограничений в еде;</li>
            <li>Быстрое привыкание;</li>
            <li>Безопасность.</li>
        </ul>
        <p>
            <b>В каких случаях ставят элайнеры на зубы:</b>
        </p>
        <ul>
            <li>Межзубные промежутки слишком большие. Проблема, как правило, затрагивает центральные резцы, в более редких случаях — клыки, жевательные.</li>
            <li>Зубные единицы выросли неправильно относительно всего ряда при различных типах прикуса.</li>
            <li>Диагностирована скрученность задних зубов (моляров).</li>
            <li>Необходимо закрепить результат после снятия брекетов, для того чтобы все не вернулось в прежнее состояние.</li>
            <li>Резцы неправильно смыкаются, что привело к изменению положения челюстей.</li>
        </ul>
        <p>
            <b>Конструкция противопоказана:</b>
        </p>
        <ul>
            <li>совершеннолетним при несформированных костных структурах челюстного аппарата;</li>
            <li>при наличии непрорезавшихся жевательных единиц;</li>
            <li>при пародонтозе в острой форме;</li>
            <li>если в анамнезе есть тяжелые хронические заболевания;</li>
            <li>людям с неврологическими нарушениями;</li>
            <li>с большим количеством зубного камня.</li>
        </ul>
        <p>
            Многие противопоказания носят относительный характер. Это значит, что при устранении этих обстоятельств, терапия может быть начата без каких-либо препятствий.
В таблице приведены наиболее важные сравнительные характеристики ортодонтических конструкций:
        </p>
        <br>
        <div class="tbb">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr class="table-primary">
                        <th class="cn" scope="col">Характеристика</th>
                        <th class="cn" scope="col">ЭЛАЙНЕРЫ</th>
                        <th class="cn" scope="col">БРЕКЕТЫ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Скорость лечения</td>
                        <td class="cn"> <b class="ttb"> на 30% быстрее брекетов </b></td>
                        <td class="cn">на 30% медленнее элайнеров</td>
                    </tr>
                    <tr>
                        <td>Лечебный эффект (исправление прикуса)</td>
                        <td class="cn">да</td>
                        <td class="cn">да</td>
                    </tr>
                    <tr>
                        <td>Исправляет дефекты прикуса</td>
                        <td class="cn">небольшие</td>
                        <td class="cn"><b class="ttb">значительные</b></td>
                    </tr>
                    <tr>
                        <td>Как достигается эффект</td>
                        <td class="cn">одновременное вращение и смещение в нужном направлении за счет плотного обхвата каждого зуба</td>
                        <td class="cn">последовательное вращение, смещение по горизонтали/вертикали</td>
                    </tr>
                    <tr>
                        <td>Фактическое выравнивание зубов</td>
                        <td class="cn">да</td>
                        <td class="cn">да</td>
                    </tr>
                    <tr>
                        <td>Риск испортить существующие зубы</td>
                        <td class="cn"><b class="ttb">нет</b></td>
                        <td class="cn">да</td>
                    </tr>
                    <tr>
                        <td>Возможность травмирования слизистой рта</td>
                        <td class="cn"><b class="ttb">нет</b></td>
                        <td class="cn">да</td>
                    </tr>
                    <tr>
                        <td>Риск развития кариеса</td>
                        <td class="cn"><b class="ttb">нет</b></td>
                        <td class="cn">да</td>
                    </tr>
                    <tr>
                        <td>Эффект отбеливания</td>
                        <td class="cn">только до или после лечения</td>
                        <td class="cn">только до или после лечения</td>
                    </tr>
                    <tr>
                        <td>Сложность с гигиеной</td>
                        <td class="cn"><b class="ttb">нет</b></td>
                        <td class="cn">да</td>
                    </tr>
                    <tr>
                        <td>Возможность изменения вида зубов</td>
                        <td class="cn">нет</td>
                        <td class="cn">нет</td>
                    </tr>
                    <tr>
                        <td>Реабилитационный период (после снятия)</td>
                        <td class="cn">ретейнеры - обязательная процедура</td>
                        <td class="cn">ретейнеры - ношение фиксирующих капп на ночь обязательная процедура</td>
                    </tr>
                    <tr>
                        <td>Обратимость лечения</td>
                        <td class="cn">частично обратимо</td>
                        <td class="cn">частично обратимо</td>
                    </tr>
                    <tr>
                        <td>Возможность проведения реминерализующей терапии, укрепление эмали зубов</td>
                        <td class="cn"><b class="ttb">да</b></td>
                        <td class="cn">нет</td>
                    </tr>
                    <tr>
                        <td>Стоимость</td>
                        <td class="cn"><b class="ttb">Относительно высокая</b></td>
                        <td class="cn">Приемлемая</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.ortd {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg1 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 25vw;
}
p, 
li,
b,
th,
td {
    font-size: 1.1vw;
    text-align: justify;
}
.cn {
    text-align: center;
}

@media (max-width: 790px) {

    .ortd {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    .leftimg1 {
        width: 40vw;
    }
    td,
    .ttb {
        font-size: 7px;
    }

}
</style>