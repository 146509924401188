<template>
    <a @click="$router.go(-1)">
        <button class="bm"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
        </button>
    </a> 
    <div class="menu">
        <nav>
            <router-link to="/">Главная</router-link>
            <router-link to="/about">О клинике</router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f1'}"
            >
                Услуги
            </router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f2'}"
            >
                Врачи
            </router-link>
            <router-link to="/price">Цены</router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f3'}"
            >
                Отзывы
            </router-link>
            <router-link to="/recom">Пациентам</router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f4'}"
            >
                Контакты
            </router-link>
        </nav>
    </div>
    <div class="bt">
        <button type="button" class="biz" data-bs-toggle="modal" data-bs-target="#exampleModal">Записаться на прием</button>
        <a 
            href="tel:+79276675505"
        >
            <button class="btp">Позвонить</button>
        </a>
    </div>
    <Modal />
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    components: {
        Modal,
    }
}
</script>

<style scoped>
.bm {
    background-color: white;
    border: none;
    margin-top: 3.5vh;
    margin-left: 82.6vw;
}
.bi {
    width: 40px;
    background-color: white;
}
.menu {
    display: flex;
    justify-content: center;
}
nav a {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    color: #333333;
    margin-left: 4vw;
    text-decoration: none;
    font-size: 20px;
    transition: all 0.4s ease;
}
nav a:hover {
    color: #018F3E;
    transition: all 0.4s ease;
}
nav a.router-link-exact-active {
  /* color: #018F3E; */
}
.bt {
    display: grid;
    justify-content: center;
}
.biz {
    margin-top: 5vh;
    width: 70vw;
    height: 6vh;
    background-color: #018F3E;
    border: #018F3E;
    color: white;
    border-radius: 50px;
    font-size: 16px;
}
.btp {
    margin-top: 5vh;
    width: 70vw;
    height: 6vh;
    background-color: #fff;
    border: 2px solid #018F3E;
    color: #018F3E;
    border-radius: 50px;
    font-size: 16px;
}
</style>