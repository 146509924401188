<template>
    <div class="cif">
        <table>
            <tbody>
                <tr>
                    <td class="td1">
                        <div class="ct">
                            <h4>
                                6
                            </h4>
                            <h5>врачей- <br> профессионалов</h5>
                        </div>
                    </td>
                    <td>
                        <div class="ct">
                            <h4>
                                1 100
                            </h4>
                            <h5>здоровых улыбок <br> за год</h5>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="td1">
                        <div class="ct">
                            <h4>
                                150
                            </h4>
                            <h5>оказываемых <br> видов услуг</h5>
                        </div>
                    </td>
                    <td>
                        <div class="ct">
                            <h4>
                                10 000
                            </h4>
                            <h5>довольных <br> пациентов</h5>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="de">
        <img class="im14" alt="" src="../assets/den14.png">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.cif {
    width: 83vw;
    margin: 15vh auto;
    height: 27vh;
}
.ct {
    display: flex;
    height: 22vh;
}
.td1 {    
    text-align: left;
    width: 27vw;
}
td {    
    text-align: left;
}
h4 {
    font-size: 5vw;
    background: linear-gradient(#c6fdde, #018F3E);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    width: auto;
}
h5 {
    font-size: 1.3vw;
    margin-left: 1vw;
    margin-top: 3.8vh;
}
.de {
    position: absolute;
    margin-left: 65vw;
    margin-top: -60vh;
}
.im14 {
    width: 30vw;
    opacity: 0.3;
}

@media (max-width: 790px) {

    .cif {
        width: 83vw;
        margin: 0vh auto;
        height: 65vh;
    }
    .td1 {    
        width: 42vw;
    }
    .ct {
        display: block;
        height: 17vh;
    }
    h4 {
        font-size: 40px;
    }
    h5 {
        font-size: 14px;
        margin-top: -3vh;
    }
    .de {
        margin-left: 30vw;
        margin-top: -35vh;
    }
    .im14 {
        width: 70vw;
        opacity: 0.3;
    }

}
</style>