<template>
  <div class="mrig">
    <a @click="$router.go(-1)"><button class="bm"><close-outlined /></button></a>  
  </div>
  <div class="gal">
    <img class='ii' alt="" src="../assets/im7.jpg" />
  </div>
  <div class="mr"></div>
</template>

<script>
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
  name: 'Im7View',
  components: {
    CloseOutlined,
  }  
}
</script>

<style scoped>
.mrig {
  height: 10vh;
  display: flex;
  justify-content: flex-end;
}
.gal {
  display: flex;
  justify-content: center;
}
.bm {
  transform: scale(2);
  color: #333333;
  border: #333333;
  background: #fff;
  margin-right: 3vw;
  margin-top: 3vh;
}
.ii {   
  width: 70vw;
  margin: 0 auto;
  overflow: hidden;
  object-fit: cover;
}
.mr {
  height: 10vh;
}
</style>