<template>
    <Obz />
    <Head />
    <div class="con">
        <br>
            <p class="tc">
                <b>
                ПРАВИЛА, УСЛОВИЯ И ПОРЯДОК <br>
предоставления платных стоматологических услуг <br><br><br>    
        </b>
        </p>
        <p>
            I. Общие положения<br>

1.Настоящие правила определяют порядок и условия предоставления платных медицинских стоматологических услуг в ООО "Бьютифул Дент"<br>

2. Платные медицинские услуги предоставляются на основании перечня работ (услуг), составляющих медицинскую деятельность и указанных в лицензии на осуществление медицинской деятельности, выданной в установленном порядке.<br>

3. Предоставление платных медицинских услуг оформляется договором.<br>

Требования к платным медицинским услугам, в том числе к их объему и срокам оказания, определяются по соглашению сторон договора.<br>

4. Настоящие Правила в наглядной и доступной форме доводятся исполнителем (ООО "Бьютифул Дент") до сведения потребителя (пациента).<br>

 

II. Основные понятия и определения<br>

Медицинская услуга - медицинское вмешательство или комплекс медицинских вмешательств, направленных на профилактику, диагностику и лечение заболеваний, медицинскую реабилитацию и имеющих самостоятельное законченное значение.<br>

Платные медицинские услуги - медицинские услуги, предоставляемые на возмездной основе за счет личных средств граждан, средств юридических лиц и иных средств на основании договоров, в том числе договоров добровольного медицинского страхования (далее - договор).<br>
Потребитель - физическое лицо, имеющее намерение получить либо получающее платные медицинские услуги лично в соответствии с договором. Потребитель, получающий платные медицинские услуги, является пациентом, на которого распространяется действие Федерального закона от 21.11.2011 N 323-ФЗ "Об основах охраны здоровья граждан в Российской Федерации".<br>
Заказчик - физическое (юридическое) лицо, имеющее намерение заказать (приобрести) либо заказывающее (приобретающее) платные медицинские услуги в соответствии с договором в пользу потребителя.<br>
Исполнитель - медицинская организация, предоставляющая платные медицинские услуги потребителям.<br>

 

III. Условия предоставления платных медицинских услуг<br>

1. ООО "Бьютифул Дент" не участвует в системе ОМС (обязательное медицинское страхование) по реализации программы государственных гарантий бесплатного оказания гражданам медицинской помощи и территориальной программы государственных гарантий бесплатного оказания гражданам медицинской помощи (далее соответственно программа, территориальная программа).<br>

Возможность получения соответствующих видов и объемов медицинской помощи без взимания платы в рамках программы  и территориальной программы существует в медицинских организациях, участвующих в данных программах. С программой и территориальной программой можно ознакомиться на ресепшне клиники на информационной стойке и на сайте клиники в информационно-телекоммуникационной сети "Интернет"<br>

2. ООО "Бьютифул Дент" определяет цены (тарифы) на предоставляемые платные медицинские услуги самостоятельно. Перечень и стоимость услуг, предоставляемых пациенту, указаны в прейскуранте ООО "Бьютифул Дент", действующем на момент оказания услуги, который находится в доступном для Пациента месте - на информационном стенде, а также на сайте клиники.<br>

3. При предоставлении платных медицинских услуг ООО "Бьютифул Дент" соблюдает порядки оказания медицинской помощи, утвержденные Министерством здравоохранения Российской Федерации.<br>

4. Платные медицинские услуги предоставляются в полном объеме стандарта медицинской помощи, утвержденного Министерством здравоохранения Российской Федерации, либо по просьбе потребителя в виде осуществления отдельных консультаций или медицинских вмешательств, в том числе в объеме, превышающем объем выполняемого стандарта медицинской помощи.<br>

 

IV. Информация об исполнителе и предоставляемых им медицинских услугах<br>

1. На сайте ООО "Бьютифул Дент" в информационно-телекоммуникационной сети "Интернет", а также информационном стенде ООО "Бьютифул Дент" размещена информация, содержащая следующие сведения:<br>

а) наименование;<br>

б) адрес места нахождения;<br>

в) данные документа, подтверждающего факт внесения сведений о юридическом лице в Единый государственный реестр юридических лиц, с указанием органа, осуществившего государственную регистрацию;<br>

г) сведения о лицензии на осуществление медицинской деятельности (номер и дата регистрации, перечень работ (услуг), составляющих медицинскую деятельность ООО "Бьютифул Дент" в соответствии с лицензией, наименование, адрес места нахождения и телефон выдавшего ее лицензирующего органа);<br>

д) перечень платных медицинских услуг с указанием цен в рублях, сведения об условиях, порядке, форме предоставления медицинских услуг и порядке их оплаты;<br>

е) сведения о медицинских работниках, участвующих в предоставлении платных медицинских услуг, об уровне их профессионального образования и квалификации;<br>

ж) режим работы организации, график работы медицинских работников, участвующих в предоставлении платных медицинских услуг;<br>

з) адреса и телефоны органа исполнительной власти субъекта Российской Федерации в сфере охраны здоровья граждан, территориального органа Федеральной службы по надзору в сфере здравоохранения и территориального органа Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека.<br>

2. Информация, размещенная на информационных стендах (стойках) доступна неограниченному кругу лиц в течение всего рабочего времени ООО "Бьютифул Дент". Информационные стенды (стойки) расположены в доступном для посетителей месте и оформлены таким образом, чтобы можно было свободно ознакомиться с размещенной на них информацией.<br>

3. Исполнитель предоставляет для ознакомления по требованию потребителя (пациента):<br>

а) копию учредительного документа ООО "Бьютифул Дент";<br>

б) копию лицензии на осуществление медицинской деятельности с приложением перечня работ (услуг), составляющих медицинскую деятельность ООО "Бьютифул Дент" в соответствии с лицензией.<br>

4. При заключении договора по требованию потребителя предоставляется в доступной форме информация о платных медицинских услугах, содержащая следующие сведения:<br>

а) порядки оказания медицинской помощи и стандарты медицинской помощи, применяемые при предоставлении платных медицинских услуг;<br>

б) информация о конкретном медицинском работнике, предоставляющем соответствующую платную медицинскую услугу<br>

(его профессиональном образовании и квалификации);<br>

в) информация о методах оказания медицинской помощи, связанных с ними рисках, возможных видах медицинского вмешательства, их последствиях и ожидаемых результатах оказания медицинской помощи;<br>

г) другие сведения, относящиеся к предмету договора.<br>

5. До заключения договора исполнитель в письменной форме уведомляет потребителя (пациента) о том, что несоблюдение указаний (рекомендаций) исполнителя (медицинского работника, предоставляющего платную медицинскую услугу), в том числе назначенного режима лечения, могут снизить качество предоставляемой платной медицинской услуги, повлечь за собой невозможность ее завершения в срок или отрицательно сказаться на состоянии здоровья потребителя.<br>

 

V. Порядок заключения договора и оплаты медицинских стоматологических  услуг<br>

1. Договор заключается потребителем (пациентом) и исполнителем (ООО "Бьютифул Дент") в письменной форме.<br>

2. Договор содержит:<br>

а) сведения об исполнителе:<br>

наименование медицинской организации - юридического лица, адрес места нахождения, данные документа, подтверждающего факт внесения сведений о  юридическом лице в Единый государственный реестр юридических лиц,<br>

номер лицензии на осуществление медицинской деятельности, дата ее регистрации с указанием перечня работ (услуг), составляющих медицинскую деятельность ООО "Бьютифул Дент" в соответствии с лицензией, наименование, адрес места нахождения и телефон выдавшего ее лицензирующего органа;<br>

б) фамилию, имя и отчество (если имеется), паспортные данные, адрес места жительства и телефон потребителя (законного представителя потребителя);<br>

в) перечень платных медицинских услуг, предоставляемых в соответствии с договором, их стоимость, срок гарантии,  фамилия, имя ,отчество и подпись специалиста, оказавшего услуги,  указывается в приложениях  к договору, которые являются его неотъемлемой частью;<br>

г) сроки и порядок  оплаты платных медицинских стоматологических  услуг;<br>

д) права и обязанности сторон;<br>

е) ответственность сторон за невыполнение условий договора;<br>

ж) порядок изменения и расторжения договора;<br>

з) иные условия, определяемые по соглашению сторон.<br>

3. Договор составляется в 2 экземплярах, один из которых находится у исполнителя, второй - у потребителя.<br>

4. На предоставление платных медицинских услуг может быть составлена смета. Ее составление при этом она является неотъемлемой частью договора.<br>

5. В случае если при предоставлении платных медицинских стоматологических услуг требуется предоставление на возмездной основе дополнительных медицинских услуг, не предусмотренных договором, исполнитель обязан предупредить об этом потребителя (пациента).<br>

Без согласия потребителя  исполнитель не вправе предоставлять дополнительные медицинские услуги на возмездной основе.<br>

6. В случае если при предоставлении платных медицинских стоматологических услуг потребуется предоставление дополнительных медицинских услуг по экстренным показаниям для устранения угрозы жизни потребителя, при внезапных острых заболеваниях, состояниях, обострениях хронических заболеваний, такие медицинские услуги оказываются без взимания платы в соответствии с Федеральным законом "Об основах охраны здоровья граждан в Российской Федерации".<br>

7. В случае отказа потребителя после заключения договора от получения медицинских услуг договор расторгается. Исполнитель информирует потребителя  о расторжении договора по инициативе потребителя, при этом потребитель  оплачивает исполнителю фактически понесенные исполнителем расходы, связанные с исполнением обязательств по договору.<br>

8. Потребитель обязан оплатить предоставленную исполнителем медицинскую услугу в сроки и в порядке, которые определены договором.<br>

9. Потребителю  в соответствии с законодательством Российской Федерации выдается документ, подтверждающий произведенную оплату предоставленных медицинских услуг (бланк строгой отчетности (документ установленного образца)).<br>

10. Ознакомиться с медицинскими документами (копии медицинских документов, выписки из медицинских документов), отражающие состояние здоровья пациента можно по письменному заявлению потребителя (или его законного представителя) на имя директора ООО "Бьютифул Дент" после получения платных медицинских услуг. Все документы являются собственностью организации.<br>

 

VI. Порядок предоставления платных медицинских услуг<br>

Исполнитель предоставляет платные медицинские услуги, качество которых должно соответствовать условиям договора, а при отсутствии в договоре условий об их качестве - требованиям, предъявляемым к услугам соответствующего вида.<br>

В случае если федеральным законом, иными нормативными правовыми актами Российской Федерации предусмотрены обязательные требования к качеству медицинских услуг, качество предоставляемых платных медицинских услуг должно соответствовать этим требованиям.<br>

Платные медицинские услуги предоставляются при наличии информированного добровольного согласия потребителя (законного представителя потребителя), данного в порядке, установленном законодательством Российской Федерации об охране здоровья граждан.<br>

Исполнитель предоставляет потребителю (законному представителю потребителя) по его требованию и в доступной для него форме информацию:<br>

о состоянии его здоровья, включая сведения о результатах обследования, диагнозе, методах лечения, связанном с ними риске, возможных вариантах и последствиях медицинского вмешательства, ожидаемых результатах лечения;<br>

об используемых при предоставлении платных медицинских услуг лекарственных препаратах и медицинских изделиях, в том числе о сроках их годности (гарантийных сроках), показаниях (противопоказаниях) к применению.<br>

Исполнитель обязан при оказании платных медицинских услуг соблюдать установленные законодательством Российской Федерации требования к оформлению и ведению медицинской документации и учетных и отчетных статистических форм, порядку и срокам их представления.<br>

 

VII. Заключительные положения<br>

6.1. Контроль за соблюдением учреждения правил предоставления платных медицинских услуг осуществляет Федеральная служба по надзору в сфере защиты прав потребителей.<br>

6.2. В соответствии с законодательством Российской Федерации учреждение несет ответственность перед потребителем за неисполнение или ненадлежащее исполнение условий договора, несоблюдение требований, предъявляемых к методам диагностики, профилактики и лечения, разрешенным на территории Российской Федерации, а также в случае причинения вреда здоровью и жизни граждан.<br>

6.3. Учреждение освобождается от ответственности за неисполнение или ненадлежащее исполнение платной медицинской услуги, если это произошло вследствие непреодолимой силы, а также по иным основаниям, предусмотренным законодательством.<br>

        </p>
            <br><br><br>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.con {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
.tc {
    text-align: center;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .con {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }

}
</style>