<template>
    <Obz />
    <Head />
    <div class="ortd">
        <h1>
            Ортодонтия
        </h1> 
        <ul>
            <li>
                Установка эстетических брекет-систем;
            </li>
            <li>
                Установка металлических брекет-систем;
            </li>
            <li>
                Установка лингвальных брекет-систем;
            </li>
            <li>
                Ортодонтические каппы;
            </li>
            <li>
                Установка ортодонтических аппаратов;
            </li>
            <li>
                Комплексный уход и обслуживание ортодонтических конструкций.
            </li>
        </ul>
        <p>
            <img src="../assets/im30.jpg" alt="" class="leftimg">
            <b>Брекет-системы</b> - это специальные устройства, предназначенные для исправления нарушений прикуса и коррекции неправильного положения зубов. Лечение брекет-системами предполагает длительное постоянное воздействие на зубы пациента,  приводящее в конечном итоге к изменению их положения в лунке. <br><br>

Брекет-система – это довольно непростой ортодонтический аппарат, состоящий из брекетов, замков или колец с замками на 6 и 7 зубы, ортодонтической дуги, эластических или металлических лигатур и эластической тяги. Рассмотрим эти элементы подробнее.
        </p>
        <p>
           Брекет - это несъемный элемент, прочно закрепляемый основанием на поверхности зуба. Используемый для этого специальный стоматологический клей обладает способностью выделять фтор, защищая таким образом эмаль вокруг брекета от деминерализации. В паз брекета вставляется ортодонтическая дуга. Крылья брекета служат для фиксации дуги в пазе с помощью металлических или эластических лигатур. На некоторых зубах на крыле брекета может находиться маленький металлический крючок, к которому в ходе лечения будет фиксироваться эластическая тяга.
        </p>
        <p>
            <!-- <img src="../assets/di9.png" alt="" class="leftimg1"> -->
            Основной элемент брекет-системы, его движущая сила - это ортодонтическая дуга. Обычно ортодонтичекие дуги изготавливаются из никель-титановых,  титаново-молибденовых сплавов, или из высококачественной стали. Главное свойство применяемых здесь материалов – «память» формы. Это обозначает, что изделие при определенной температуре (допустим 36,6 градусов Цельсия) всегда «вспоминает» и принимает заданную ему певоначально форму. <br>

Ортодонтической дуге заранее придана форма правильного зубного ряда. Постоянное усилие, оказываемое дугой, зафиксированной в брекетах, способствует выравниванию зубов. Дуги бывают разных диаметров и сечений. Обычно в начале лечения используют более тонкие дуги круглого сечения, периодически заменяя их на более толстые, прямоугольного сечения, полностью заполняющие паз брекета.
        </p>
        <p>
            Эластические лигатуры служат для фиксации ортодонтической дуги в пазе брекета. Они могут быть прозрачными или цветными, под цвет металла (серебряными) или декоративными. Цветные и декоративные лигатуры оживляют вид брекет-системы, превращая ее из ортодонтической конструкции в забавное, экзотическое, стильное украшение.За счет своей эластичности лигатуры со временем растягиваются, соединение брекета с дугой становится более подвижным, поэтому их нужно менять раз в 3-4 недели. Кроме того, прозрачные лигатуры имеют свойство окрашиваться пищевыми красителями, что также диктует необходимость их замены. <br>

Ещё один вид эластических элементов в брекет-системе - это эластическая цепочка. Сокращаясь, она начинает сближать по дуге брекеты, а значит и зубы. Естественно, что, будучи подобно лигатурам, эластичной, она также подлежит периодической замене. Иногда те же функции выполняет металлическая пружина.
        </p>
        <p>
            Металлические лигатуры фиксируют дугу в пазе брекета плотнее и надежнее, чем эластичные, поэтому их применяют не сразу. Хотя со временем металлический лигатуры частично теряют плотность фиксации, меняют их обычно уже при установке новой дуги. <br>

Иногда металлическая лигатура фиксируется не на каждый зуб, а охватывает группу зубов в виде восьмерки, это придает все группе зубов большую устойчивость. Хвостики, остающиеся от металлических лигатур, загибаются к зубу и обычно совсем не мешают.
        </p>
        <p>
            Эластическая тяга  - единственный элемент брекет-системы, ответственность за использование которого полностью возложена на пациента. Брекеты, замки, дуги, лигатуры работают на каждой челюсти отдельно. При этом большая часть ортодонтических проблем связана именно с различными несоответствиями челюстей (положения, размера и т.д.). <br>

Для того, чтобы между челюстями возникали ортодонтические силы, необходимо применение эластиков - резиновых колечек различного диаметра, надеваемых пациентом между крыльями брекетов и замками в соответствии с указаниями врача. Эластики находится в полости рта постоянно, менять их нужно два раза в день, так как они теряют свои эластичные свойства или рвутся. Иногда эластики достаточно сложны и заметны во рту, но от того, как четко и добросовестно пациент выполняет инструкции врача, зависит продолжительность и эффективность лечения. <br>

Самым популярным видом съемного ортодонтического аппарата являются «пластинки». Они состоят из базиса – пластиковой пластинки, активных элементов (винтов, пружин, дуг), пассивных элементов (накусочной площадки, наклонной плоскости) и фиксирующих элементов (кламмеров, колец). <br>

Выделяют аппараты механического и функционального действия. <br>

Аппараты механического действия ответственны за наклонение и перемещение групп зубов. Функциональные аппараты способствуют нормализации работы мышц и роста костей челюсти, а также помогают избавиться от ряда вредных привычек (сосание пальцев, закусывание щек и т.д.). Существуют также аппараты комбинированного действия, сочетающие свойства механических и функциональных.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.ortd {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 40vw;
}
.leftimg1 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 25vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .ortd {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    .leftimg1 {
        width: 40vw;
    }

}
</style>