<template>
    <div class="foo">
        <div class="fo1">
            <img class="log" alt="" src="../assets/logo.png">
            <div class="vv">
                <!-- <a href="viber://chat?number=%2B9276675505"><img class="vib" alt="" src="../assets/vib.png" /></a> -->
                <a
                    href="https://api.whatsapp.com/send?phone=79276675505"
                    target="_blank"
                    rel="noopener"
                >
                    <img class="wal" alt="" src="../assets/wal.png" />
                </a>
                <a href="https://www.instagram.com/nadi_nok"><img class="ins" alt="" src="../assets/ins.png" /></a>
                <!-- <a href="https://vk.com/club143692167" target="_blank" rel="noopener">
                    <img class='vk' alt="" src="../assets/vk.jpg"/>
                </a> -->
            </div>
            <br>
            <p>
                <router-link class="rl" to="/conf">
                    Политика конфиденциальности
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/vacancies">Вакансии</router-link>
            </p>
            <br><br>
            <p>
                © 2023 Стоматологическая клиника Beautiful Dent
            </p>
        </div>
        <div class="fo2">
            <p>
                <b>Пациентам</b>
            </p>
            <p>
                <router-link class="rl" to="/rules">Правила внутреннего распорядка</router-link>
            </p>
            <p>
                <router-link class="rl" to="/payservises">Платные стоматологические услуги</router-link>
            </p>
            <p><router-link class="rl" to="/price">Цены</router-link></p>
            <p>
                <router-link class="rl" to="/guarantee">Гарантия</router-link>
            </p>
            <p>
                <router-link class="rl" to="/deduction">Налоговые вычеты</router-link>
            </p>
            <p>
                <router-link class="rl" to="/list">
                    Полезные ссылки
                </router-link>
            </p>
        </div>
        <div class="fo3">
            <p>
                <b>Услуги</b>
            </p>
            <p>
                <router-link class="rl" to="/therapy">
                    Терапия
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/orthopedics">
                    Ортопедия
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/orthodontics">
                    Ортодонтия
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/aligners">
                    Элайнеры
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/implantation">
                    Имплантация
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/aesthetic">
                    Виниры
                </router-link>
            </p>
            <p>
                <router-link class="rl" to="/vncs">
                    Лечение дисфункции <br> ВНЧ сустава
                </router-link>
            </p>
        </div>
        <div class="fo4">
            <p>
                <b>Контакты</b>
            </p>
            <p>
                Стоматологическая клиника ООО "Бьютифул Дент"
            </p>
            <p>
                <b>Адрес:</b> 428022, г. Чебоксары, ул. Гагарина, д.30, корп.1, пом.3 <br>

ост. "гостиница Россия" или "Агрегатный завод"
            </p>
            <p>
                <b>Email:</b> <a class="rl" href="mailto:beautiful-dent@mail.ru">beautiful-dent@mail.ru</a>
            </p>
            <p>
                <b>Телефон:</b> <a class="rl" href="tel:+79276675505">8 (927) 667-55-05</a>
            </p>
            <p>
                <b>Режим работы:</b> ПН–ПТ 9:00-19:00 <br>
СБ-ВС по записи 9:00-15:00 <br>
ЧТ выходной
            </p>
        </div>
        <div class="mrb"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.foo {
    display: flex;
    justify-content: space-between;
    height: 45vh;
    width: 83vw;
    margin: auto;
}
.log {
    width: 11vw;
    height: auto;
}
.vv {
    margin-top: 3.5vh;
    margin-bottom: 2vh;
}
.vib {
    width: 2vw;
    margin-right: 1vw;
}
.wal {
    width: 2.5vw;
    margin-right: 1vw;
}
.ins {
    width: 2vw;
    margin-right: 1vw;
}
.vk {
    width: 2vw;
}
p,
a {
    font-size: 0.8vw;
}
.rl {
    color: #018F3E;
}

@media (max-width: 790px) {

    .foo {
        display: block;
        width: 90vw;
        margin-top: 10%;
    }
    .log {
        width: 50vw;
        height: auto;
    }
    .vib {
        width: 40px;
        margin-right: 2vw;
    }
    .wal {
        width: 50px;
        margin-right: 2vw;
    }
    .ins {
        width: 40px;
        margin-right: 2vw;
    }
    p,
    .rl {
        font-size: 12px;
    }
    .mrb {
        height: 10vh;
    }
    .fo2,
    .fo3,
    .fo4 {
        margin-top: 3vh;
    }
}
</style>