<template>
    <div class="nnav">
        <nav>
            <router-link to="/">Главная</router-link>
            <router-link to="/about">О клинике</router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f1'}"
            >
                Услуги
            </router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f2'}"
            >
                Врачи
            </router-link>
            <router-link to="/price">Цены</router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f3'}"
            >
                Отзывы
            </router-link>
            <router-link to="/recom">Пациентам</router-link>
            <router-link 
                class="rl" 
                :to="{path: '/', hash: '#f4'}"
            >
                Контакты
            </router-link>
            <!-- <a href="viber://chat?number=%2B9276675505"><img class="vib" alt="" src="../assets/vib.png" /></a> -->
            <a
                href="https://api.whatsapp.com/send?phone=79276675505"
                target="_blank"
                rel="noopener"
            >
                <img class="wal" alt="" src="../assets/wal.png" />
            </a>
            <a href="https://www.instagram.com/nadi_nok"><img class="ins" alt="" src="../assets/ins.png" /></a>
            
        </nav>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.nnav {
    background: #f4f5f5;
    position: sticky;
    top: 0em;
    min-height: 4em;
    z-index: 20;
    display: flex;
    align-items: center;
}
nav {
    width: 88vw;
    margin: 2vh auto;
}
nav a {
    color: #333333;
    margin-left: 3.3vw;
    text-decoration: none;
    font-size: 1.3vw;
    transition: all 0.4s ease;
}
nav a:hover {
    color: #018F3E;
    transition: all 0.4s ease;
}
nav a.router-link-exact-active {
    color: #018F3E;
}
.vib {
    width: 2vw;
}
.wal {
    width: 2.5vw;
}
.ins {
    width: 2vw;
}

@media (max-width: 790px) {

    .nnav {
        display: none;
    }

}
</style>