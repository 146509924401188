<template>
    <Obz />
    <Head />
    <div class="wr">
        <h1><img src="../assets/cham.jpg" alt="" class="leftimg"> Чамеев Дмитрий Федорович</h1>
        <h2>Врач-стоматолог-хирург</h2>
        <p>
            Стаж более 10 лет <br>
            <b>Диплом об образовании: </b> <br> К № 14266 от 30.06.2012 г. «ЧГУ им. И.Н.Ульянова»  г.Чебоксары  специальность «Стоматология» <br>
            <b>Аккредитация «Стоматология хирургическая»:</b> <br> № 7722 026415989   ФГБОУ ДПО РМАНПО Минздрава России от 15.06.2022
        </p>
        <p>
            Прием в воскресенье - по записи
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue' 
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.wr {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
h2 {
    font-size: 1.2vw;
}
p {
    font-size: 1vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 17vw;
}

@media (max-width: 790px) {

    .wr {
        width: 80vw;
        height: auto;
        margin-top: 0;
    }
    .leftimg {
        width: 80vw;
        margin-bottom: 7vh;
    }
    h1 {
        font-size: 19px;
    }
    h2 {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }

}
</style>