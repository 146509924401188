<template>
    <button type="button" class="hithere" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <img src="../assets/den15.png" alt="">   
    </button>
    <Modal />
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    components: {
        Modal,
    }
}
</script>

<style scoped>

.hithere {
    position: fixed;
    margin-left: 80vw;
    margin-top: 80vh;
    width: 5.5vw;
    height: 5.5vw;
    background: none;
    border: none;
    z-index: 50;
    animation: hithere 2s ease infinite;
}
@keyframes hithere {
    30% { transform: scale(1.2); }
    40%, 60% { transform: rotate(-20deg) scale(1.2); }
    50% { transform: rotate(20deg) scale(1.2); }
    70% { transform: rotate(0deg) scale(1.2); }
    100% { transform: scale(1); }
}
img {
    width: 5.5vw;
    height: 5.5vw;
}

@media (max-width: 790px) {

    .hithere {        
        margin-left: 80vw;
        margin-top: 85vh;
        width: 12vw;
        height: 12vw;
    }
    img {
        width: 12vw;
        height: 12vw;
    }

}

</style>