<template>
    <Obz />
    <Head />
    <div class="con">
        <br>
            <p class="tc">
                <b>
                ПОЛОЖЕНИЕ <br>
об установлении срока службы при оказании стоматологической помощи в ООО «Бьютифул Дент» <br><br><br>    
        </b>
        </p>
        <p>
            Настоящее Положение разработано в соответствии с Гражданским кодексом РФ, Законом РФ «О защите прав потребителей», «Правилами предоставления платных медицинских услуг населению медицинскими учреждениями», Законом РФ «Основы законодательства Российской Федерации об охране здоровья граждан». <br>

 

I. Общие положения.<br>

Гарантийный срок исчисляется с момента передачи результата услуги потребителю (пациенту), т.е. с момента завершения оказания услуги.<br>

Срок службы услуги (товара) исчисляется с момента ее завершения (изготовления) и определяется периодом времени, в течение которого товар (услуга) пригоден к использованию. В течение установленного срока службы должна быть обеспечена безопасность результата услуги (работы). Безопасность стоматологической услуги (работы) обеспечивается исполнителем в процессе ее оказания.<br>

В случае выявления в течение срока службы существенных недостатков в выполненной работе, потребитель (пациент) вправе предъявить исполнителю требование о безвозмездном устранении недостатков, если докажет, что недостатки возникли до принятия им результата работы или по причинам, возникшим до этого момента. Указанные требования должны быть устранены исполнителем в течение 30 дней со дня предъявления требования потребителем (пациентом) в письменном виде.<br>

В случае возникновения любых замечаний к выполненным работам и услугам пациент должен обратиться к администратору (по телефону или лично) и изложив суть замечания записаться на бесплатный прием к лечащему врачу. Выполнение гарантийных обязательств производится бесплатно для пациента.<br>

В течение гарантийного срока замечания пациентов рассматриваются по письменному заявлению установленной формы. Бланк заявления можно получить у администратора клиники.<br>

 

II. Основными понятия, используемые в настоящем Положении:<br>

Гарантия- ручательство; условие, обеспечивающее что-либо.<br>

Безусловные или обязательные гарантии- это гарантии на соблюдение медицинских канонов, отраслевых стандартов и прав потребителей услуг.<br>

Прогнозируемые гарантии - это гарантии предвиденные и устанавливаемые с учетом выявленных обстоятельств, опыта врача и используемых технологий. Такие гарантии даются:<br>

либо в виде сроков (гарантийного срока и срока службы) бесплатного устранения недостатков, обнаруженных после лечения и возникших не по вине пациента;<br>

либо в виде процента успешности лечения (если сроки не могут быть определены в силу специфики данного стоматологического вмешательства).<br>

Срок службы - это период пользования результатами работы врача при наличии конкретных обстоятельств (время службы пломбы или ортопедической конструкции).<br>

Гарантийный срок - это период бесплатного устранения Клиникой устранимых недостатков, которые являются следствием мелких недоделок, неучтенных врачом обстоятельств или его ошибок. Устранимые недостатки - подгонка пломбы по прикусу, дополнительная полировка разных поверхностей зуба, снятие чувствительности, корректировка цвета и формы восстановленного зуба, подшлифовка ложа съемного зубного протеза.<br>

Обстоятельства установления гарантий- это ряд объективных факторов, заявленных пациентом и выявленных врачом в конкретной клинической ситуации, которые могут сказаться на результатах лечения определенным образом - позитивно, нейтрально или негативно:<br>

состояние здоровья пациента - наличие сопутствующих заболеваний, которые напрямую или косвенно влияют (или могут повлиять в будущем) на состояние зубов и окружающих тканей (учитываются данные анкеты о здоровье)<br>

ситуация в полости рта пациента - что, как и сколь интенсивно может повлиять на выполненную работу (учитываются жалобы, данные осмотров и снимков);<br>

объем выполнения пациентом рекомендованного плана лечения - какие невыполненные рекомендации, как и сколь интенсивно могут ограничивать гарантии (пациенту разъясняется значение конкретных пунктов плана)<br>

сложность выполненной работы - необычность случая, запущенность заболевания, многофакторная причинная обусловленность;<br>

особенности использованных технологий и материалов, вариантов лечения - как и сколь явно они могут повлиять на гарантии (разъясняются достоинства и недостатки технологий и материалов, вариантов лечения, использованных врачом или выбранных пациентом);<br>

особенности профессиональной деятельности пациента, которые могут негативно повлиять на результаты стоматологического лечения: работа в горячих цехах или на холоде, химическое производство и лаборатории, порошковая металлургия и сварка, интенсивная работа с компьютером, игра на духовых инструментах, занятия спортом (бокс, борьба, баскетбол) и др.<br>

Недостаток – это несоответствие оказанной стоматологической услуги (работы) обязательным требованиям медицинских стандартов.<br>

Существенный недостаток - это недостаток, который делает в течение срока службы невозможным или недоступным использование результата услуги (работы) в соответствии с его целевым назначением, либо который не может быть устранен, либо на устранение которого требуются большие затраты (например: полный перелом протеза или выпадение пломбы).<br>

 

III. Гарантии<br>

3.1. При оказании стоматологических услуг безусловно Клиника осуществляет:<br>

использование сертифицированного оборудования и инструментов;<br>

предоставление полной, достоверной и доступной по форме информации о состоянии здоровья пациентов (с учетом их права и желания получать таковую по доброй воле);<br>

проведение консультации и консилиума;<br>

проведение лечения специалистами, имеющими сертификаты, подтверждающие право на осуществление данного вида медицинской помощи;<br>

учет показателей общего здоровья пациента при осуществлении диагностических, лечебных и профилактических мероприятий стоматологами всех специализаций;<br>

установление полного диагноза;<br>

составление рекомендуемого (предлагаемого) плана лечения;<br>

использование методик диагностики, профилактики и лечения, разрешенных на территории РФ, с соблюдением предъявляемых к ним требований;<br>

индивидуальный подбор анестетиков, что позволяет в максимальной степени исключить болевые ощущения, учитывая при этом возраст пациента, его аллергический статус, показатели общего здоровья и опыт лечения у стоматологов;<br>

безопасность лечения - обеспечивается комплексом санитарно-эпидемиологических мероприятий и использованием разрешенных к применению технологий и материалов;<br>

точная диагностика, достигаемая при наличии должного профессионального уровня специалистов, современных диагностических средств и данных дополнительных обследований;<br>

тщательное соблюдение технологий лечения, что предполагает высокопрофессиональную подготовку врачей, зубных техников и ассистентов, а также специальные средства контроля качества их работы;<br>

применение технологически безопасных, разрешенных Минздравом РФ материалов, не утративших сроков годности;<br>

проведение контрольных осмотров - по показаниям после сложного лечения или при необходимости упреждения нежелательных последствий;<br>

проведение профилактических осмотров с частотой, определяемой врачом;<br>

мероприятия по устранению или снижению степени осложнений, которые могут возникнуть в процессе или после лечения;<br>

определение риска повторения или обострения выявленных заболеваний;<br>

достижение показателей качества лечения и эстетических результатов (с учетом имеющихся в отечественной стоматологии стандартов, пожеланий пациента и объективных обстоятельств, выявленных врачом).<br>

Совокупность обязательных гарантий создает предпосылку для качественного лечения и устойчивости его результатов, оказания качественной медицинской услуги.<br>

3.2. В соответствии с действующим законодательством исполнитель ( в лице клиники, оказывающей стоматологические услуги) обязан:<br>
- в течение установленного гарантийного срока, устранять все недостатки, обнаруженные пациентом,<br>
- в течение установленного срока службы – устранять только существенные недостатки.<br>

3.3. В соответствии со ст. 10 Закона «О защите прав потребителей» исполнитель доводит до сведения пациента указанные сроки службы и сроки гарантии (в виде информации на стенде, либо в виде записи в медицинской амбулаторной карте, либо в договоре и т. д.) исполнитель рекомендует пациенту проведение необходимых мероприятий по уходу за состоянием полости рта (периодичность профилактических осмотров, проведение гигиенических мероприятий, уход за протезами, постоянное наблюдение за имплантатами и т.д. в соответствии с установленными стандартами).<br>

В случае несоблюдения пациентом указанных требований , последний лишается права ссылаться на недостатки (дефекты) в работе, возникшие в результате несоблюдения указанных требований.<br>

Стоматологические заболевания, не указанные в Положении, не имеют установленных сроков годности в связи с тем, что их лечение связано с большой степенью риска возникновения осложнений после проведенного лечения. Возникающие в результате лечения этих заболеваний осложнения лечатся в общем порядке, на возмездной основе.<br>

 

Гарантии клиники устанавливаются на материальный результат оказания услуги (пломба, винир, зубная коронка, зубные протезы, ортодонтические аппараты после снятия брекет-системы), прогнозируемые гарантии устанавливаются в виде гарантийного срока и срока службы. В связи с тем, что медицинская услуга по своему содержанию предполагает вмешательство в биологические процессы, не подконтрольные в 100% случаев воле человека, Исполнитель при надлежащем выполнении условий договора и правильном использовании достижений современной медицинской науки по объективным причинам не может гарантировать Потребителю достижение положительного результата оказания услуги. Однако, основываясь на опыте, Исполнитель определяет средние прогнозируемые гарантийные сроки.<br>

В конкретном случае (при выявленных обстоятельствах лечения) прогнозируемые гарантийные показатели могут быть больше или меньше «средних». Лечащий врач аргументирует, что и почему он может или не может гарантировать в каждом конкретном случае.<br>

Гарантии в полном объеме действуют только при соблюдении следующих условий:<br>

Полном выполнении рекомендованного плана лечения<br>

Наличии фиксированного прикуса и полного восстановления целостности зубных рядов (28 зубов). В остальных случаях сроки сокращаются на 50%.<br>

Хорошей гигиене полости рта. При неудовлетворительной гигиене полости рта указанные сроки гарантии сокращаются на 50%. Уровень гигиены определяется врачом-стоматологом.<br>

Соблюдении графиков профилактических осмотров. При нарушении графиков профилактических осмотров, указанных в Листе гарантийного учета , гарантии аннулируются.<br>

При не проведении профессиональной гигиены полости рта, согласно индивидуального графика (минимум 1 раз в 6 месяцев), сроки сокращаются на 50%<br>

Если в период действия гарантий у пациента не возникнут (не проявятся) заболевания внутренних органов, а также изменения физиологического состояния организма (вследствие беременности, приема лекарственных препаратов, вредных внешних воздействий), которые способны негативно повлиять на достигнутые результаты стоматологического лечения.<br>

Если в период лечения у врача пациент не будет лечить то же самое у специалиста другой клиники<br>

Существующие врачебные методики протезирования также не позволяют установить гарантийный срок при наличии следующих диагнозов или случаев:<br>

наличие подвижности зубов. Гарантийный срок устанавливает врач;<br>

наличие сопутствующего заболевания: пародонтит, пародонтоз. Обязательным условием предоставления гарантии является проведение курса профессиональной гигиены 2-4 раза в год. Гарантийный срок устанавливает врач в зависимости от степени тяжести заболевания десен;<br>

при желании потребителя (пациента) выполнить работу по определенной им схеме и (или) при отсутствии четких медицинских показаний к выполнению определенных видов протезирования врач-стоматолог имеет право установить гарантийный срок на ортопедическую услугу 1 месяц, предварительно известив об этом потребителя (пациента). Все переделки ,изменения конструкции, терапевтическая подготовка зубов под протезирование выполняются за счет потребителя (пациента).<br>

 

3.4. Исполнителем может быть установлен сокращенный гарантийный срок на ортопедические услуги. Об уменьшении срока гарантии на ортопедические услуги исполнитель обязательно сообщает потребителю (пациенту) с записью в амбулаторной карте.<br>

3.5. В период срока гарантии и срока службы перебазировка ортопедических конструкций осуществляется на возмездной основе.<br>

3.6. Временные ортопедические конструкции обязательно должны быть заменены на постоянные. Если по вине потребителя (пациента) (по различным причинам) временные конструкции не заменены на постоянные, то дальнейшая ответственность с ООО «Бьютифул Дент» и врача-стоматолога снимается.<br>

3.7. До момента сдачи ортопедической конструкции потребитель (пациент) имеет право требовать переделки/ коррекции работы по причинам:<br>

• выполненная конструкция не соответствует эстетическим требованиям (неверно выполнен цвет, размер или форма зуба);<br>

• выполненная конструкция не соответствует конструкции, определенной в плане протезирования (плане лечения).<br>

3.8. Замена матрицы замковых креплений производится на возмездной основе независимо от срока.<br>

 

IV. Стоматологические услуги, на которые устанавливаются только проценты успешности лечения.<br>

На остальные услуги установление гарантийных сроков не представляется возможным в связи с тем, что они связаны с большой степенью риска возникновения осложнений после проведенного лечения. Возникающие в результате лечения этих заболеваний осложнения лечатся в общем порядке, на возмездной основе.<br>

К их числу относятся:<br>

· обработка и пломбирование корневых каналов;<br>

· профессиональная гигиеническая обработка полости рта;<br>

· лечение заболеваний пародонта (терапевтическое и хирургическое);<br>

· ортодонтическое лечение;<br>

· профессиональное отбеливание зубов;<br>

 

Обработка и пломбирование корневых каналов<br>

С учетом мировой практики и опыта врачей-стоматологов ООО «Бьютифул Дент» при лечении каналов благоприятный результат достигается в 45% - 50% случаев. Результат лечения в каждом конкретном случае зависит не только от его качества, но и от индивидуальных особенностей организма потребителя (пациента).<br>

Заболевания пародонта (воспаление десны и окружающих зуб тканей)<br>

Исполнитель гарантирует потребителю (пациенту) в 80% случаев (при соблюдении условий, которые определяет врач) стабилизацию процесса и ремиссию, что способствует сохранению зубов на длительный период.<br>

Выполнение гарантийных обязательств не продлевает срока гарантии.<br>

        </p>
            <br><br><br>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.con {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
.tc {
    text-align: center;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .con {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }

}
</style>