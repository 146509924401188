<template>
    <Obz />
    <Head />
    <div class="vncs">
        <h1>
            Лечение дисфункции ВНЧ сустава
        </h1>
        <p>
            <b>
                <!-- <img src="../assets/im36.jpg" alt="" class="leftimg">  -->
                Дисфункция Височно-нижнечелюстного сустава -</b>
            функциональная патология височно-нижнечелюстного сустава, обусловленная мышечными, окклюзионными и пространственными нарушениями. По статистике, от 25 до 75% пациентов стоматологов имеют признаки дисфункции ВНЧС. В структуре патологии челюстной патологии дисфункции ВНЧС принадлежит ведущее место - более 80%. 

        </p>
        <p>
            <b>Как распознать?</b> <br>
            Если Вас мучает <b> головная боль,</b> висках, шее, ощущаете <b> щелчки в суставе,</b> ограничение амплитуды открывания рта, <b> шум и звон в ушах,</b> Вы «скрипите зубами», храпите, а лечение у невролога дает непродолжительный положительный эффект, то Вам обязательно нужно показаться врачу-стоматологу-ортопеду. 
        </p>
        <p>
            В настоящее время диагностическими критериями дисфункции ВНЧС принято считать следующие группы симптомов:
        </p>
        <ol>
            <li>
                <p>
                    <b>Звуковые феномены в височно-нижнечелюстном суставе.</b><br>
                    Наиболее частой жалобой пациентов с дифункцией ВНЧС являются щелчки в суставе, возникающие при открывании рта, жевании, зевании. Иногда щелканье может быть настолько громким, что его слышат окружающие люди. При этом боль в суставе присутствует не всегда. Среди других шумовых явлений могут иметь место хруст, крепитация, хлопающие звуки и пр.
                </p>
            </li>
            <li>
                    <b>Блокирование («запирание», «заклинивание») височно-нижнечелюстного сустава.</b> <br>
                    <p>
                        Характеризуется неравномерностью движения в суставе при открывании рта. То есть для того, чтобы широко открыть рот, пациент должен сначала уловить оптимальное положение нижней челюсти, подвигать ею из стороны в сторону, найдя точку, где сустав «отпирается».                    
                    </p>
            </li>
            <li>
                <b>Болевой синдром.</b><br>
                <p>
                    При дисфункции ВНЧС определяется болезненность в триггерных точках: жевательных, височной, подъязычной, шейных, крыловидной, грудино-ключично-сосцевидной, трапециевидной мышцах. <br>
Типичны прозопалгия (лицевая боль), головные боли, боли в ушах, зубная боль, давление и боль в глазах. Болевой синдром при дисфункции ВНЧС может имитировать невралгию тройничного нерва, шейный остеохондроз, артрит ВНЧС, отит и другие заболевания.
                </p>
            </li>
            <li>
                <b>Прочие симптомы.</b><br>
                <p>
                    При дисфункции ВНЧС могут иметь место головокружения, нарушение сна, депрессия, бруксизм, дисфагия, шум или звон в ушах, ксеростомия, глоссалгия, парестезии, фотофобия, храп, апноэ во сне и др. <br>
Дисфункция ВНЧС является мультидисциплинарной патологией, поэтому ее решение часто требует совместных усилий специалистов в области стоматологии, неврологии, психологии.
                </p>
            </li>
        </ol>
        <p>
            <b>Стоматологическое лечение дисфункции ВНЧС, <img src="../assets/im11.jpg" alt="" class="leftimg"></b>
            по показаниям, может включать в себя меры, направленные на воссоздание правильного смыкания зубов (избирательное пришлифовывание зубов, устранение завышающих пломб, грамотное протезирование или перепротезирование, использование временных ортопедических конструкций и накладок и пр.). Для коррекции неправильного прикуса проводится лечение с помощью брекет-систем. В некоторых случаях ортопедическому и ортодонтическому лечению дисфункции ВНЧС несъемными аппаратами предшествует ношение ортопедических шин или кап.
        </p>
        <p>
            <b>Прогноз и профилактика дисфункции ВНЧС.</b> <br>
            Лечение дисфункции ВНЧС является обязательным. Пренебрежение данной проблемой может быть чревато развитием дистрофических изменений (артроза) и обездвиживания височно-нижнечелюстного сустава (анкилоза). Комплексное лечение дисфункции ВНЧС с учетом этиологических факторов гарантирует положительный результат. <br>
Предупреждение дисфункции ВНЧС требует снижения уровня стресса и избыточных нагрузок на сустав, своевременного и качественного протезирования зубов, исправления прикуса, коррекции нарушений осанки, лечения бруксизма.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.vncs {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 40vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}
img {
    width: 50vw;
}

@media (max-width: 790px) {

    .vncs {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    img {
        width: 90vw;
    }

}
</style>