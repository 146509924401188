<template>
    <div class="zap"></div>
    <div class="fr">
        <div class="cen">
            <h2>Запишитесь на консультацию к специалисту, задайте вопрос или обращение
            </h2>
            <p>
                Оставьте свои данные и мы свяжемся с вами в течение 30 мин
            </p>
            <div>
                <form ref="form" @submit.prevent="sendEmail">    

                    <p class="nam">
                        Ваше имя
                    </p>
                    <input required type="text" class="itx" name="user_name">
                    <p class="tel">
                        Ваш телефон
                    </p>
                    <input required type="number" class="inb" name="user_phone">
                    <!-- <div class="bt"> -->
                    <button 
                        class="bts"
                        type="submit" 
                    >Отправить
                    </button>
                    <!-- </div> -->

                    <!-- <input 
                    required
                    type="text" 
                    placeholder="Имя"
                    name="user_name"
                    >
                    <input 
                    required
                    type="number" 
                    placeholder="Телефон"
                    name="user_phone"
                    > -->
                    <!-- <textarea 
                    type="text" 
                    placeholder="Добавьте сообщение..."
                    name="message"
                    ></textarea> -->
                    
                    <div class="vis" 
                    v-show="visible"
                    >
                    <p class="sho">
                        Сообщение успешно отправлено, <br>
                        скоро мы Вам перезвоним.
                    </p> 
                    
                    </div>  
                </form>
            </div>
            <p class="zp">Нажимая на "Отправить", вы соглашаетесь с 
                <router-link class="rl" to="/conf">политикой конфиденциальности</router-link>
            </p>
        </div>
        <h3 class="hn"><b>Не можете определиться с приемом?</b></h3>
        <div class="ne">
            <h3>Закажите</h3>
                <button type="button" class="biz" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    обратный звонок    
                </button>
            <h3 class="hh3">
                или позвоните нам 
            </h3>
        </div>
        <a 
            href="tel:+79276675505"
        >
            <h4>
                8 927 667 55 05
            </h4>
        </a> 
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    data: () => ({     
        visible: false,   
    }),
    methods: {
        sendEmail(event) {
        emailjs.sendForm('service_m8cijjj', 'template_uz9n24p', this.$refs.form, 'r9W7BALCELVHJAelr')
            .then((result) => {
            console.log('SUCCESS!', result.text);
            }, (error) => {
            console.log('FAILED...', error.text);
            });
            event.target.reset();
            this.visible = true;
            setTimeout(() => this.visible = false, 10000);
        }
    }
}
</script>

<style scoped>
.zap {
    /* background-image: url(../assets/di19.jpg); */
    background-size: cover;
    height: 80vh;
}
.fr {
    background-color: white;
    width: 40vw;
    height: 70vh;
    border-radius: 1vw;
    margin-top: -75vh;
    margin-left: 5vw;
    border: 0.2vw solid #018F3E;
}
.cen {
    width: 30vw;
    margin: 0 auto;
}
h2 {
    font-size: 1.2vw;
    position: absolute;
    margin-top: 2vw;
    width: 30vw;
}
p {
    font-size: 0.8vw;
    position: absolute;
    margin-top: 5.5vw;
}
input {
    margin-top: 10px;
    border: #333333;
    width: 100%;
    height: 50px;
    padding: 20px;
}

.vis {
  margin-top: 20px;
  font-size: 17px;
  color: #fff;
}

.nam {
    font-size: 0.8vw;
    position: absolute;
    margin-top: 7.5vw;
}
.itx {
    position: absolute;
    margin-top: 9vw;
    border: 0.1vw solid #333333;
    height: 5vh;
    width: 20vw;
    border-radius: 1vw;
    padding-left: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance:textfield;
}
.tel {
    font-size: 0.8vw;
    position: absolute;
    margin-top: 12.5vw;
}
.inb {
    position: absolute;
    margin-top: 14vw;
    border: 0.1vw solid #333333;
    height: 5vh;
    width: 20vw;
    border-radius: 1vw;
    padding-left: 30px;
}
.bts {
    position: absolute;
    margin-top: 18vw;
    width: 15vw;
    height: 6vh;
    background-color: #018F3E;
    border: #018F3E;
    color: white;
    border-radius: 50px;
    font-size: 1vw;
    transition: all 0.4s ease;
}
.bts:hover {
    transform: scale(1.03);
    transition: all 0.4s ease;
}
.zp {
    font-size: 0.8vw;
    position: absolute;
    margin-top: 22vw;
    height: auto;
}
.rl {
  color: #018F3E;
}
h3 {
    position: absolute;
    font-size: 1vw;
    margin-top: 52vh;
    margin-left: 4.8vw;
}
.biz {
    margin-top: 51.3vh;
    margin-left: 10.3vw;
    width: 13vw;
    height: 2vw;
    background: none;
    color: #018F3E;
    text-align: left;
    font-size: 1.2vw;
}
.hh3 {
    margin-top: 55.5vh;
    margin-left: 4.7vw;
}
button {
    border: none;
}
.ne {
    margin-top: 5vh;
    display: flex;
}
h4 {
    margin-top: 0vh;
    margin-left: 15.5vw;
    font-size: 1.2vw;
    color: #018F3E;
}

@media (max-width: 790px) {

    .zap {
        background-image: none;
        margin-top: -3vh;
    }
    .fr {
        width: 90vw;
        height: 87vh;
        border-radius: 7px;
    }
    .cen {
        width: 75vw;
    }
    h2 {
        font-size: 17px;
        margin-top: 5vh;
        width: 80vw;
    }
    p {
        font-size: 14px;
        margin-top: 15vh;
        width: 72vw;
    }
    .nam {
        font-size: 14px;
        margin-top: 23vh;
    }    
    .itx {
        margin-top: 27vh;
        width: 75vw;
        border-radius: 15px;
    }
    .tel {
        font-size: 14px;
        margin-top: 34vh;
    }
    .inb {
        margin-top: 38vh;
        width: 75vw;
        border-radius: 15px;
    }
    .bts {
        margin-top: 46vh;
        width: 75vw;
        border-radius: 15px;
        font-size: 14px;
    }
    .zp {
        font-size: 14px;
        margin-top: 53vh;
        width: 72vw;
    }
    h3 {
        font-size: 15px;
    }
    .hn {
        margin-top: 66vh;
    }
    .ne {
        margin-top: 20vh;
    }
    .biz {
        width: 50vw;
        height: 7vw;
        margin-left: 27vw;
        font-size: 19px;
        margin-top: 51.2vh;
    }
    h4 {
        font-size: 19px;
        margin-top: 4vh;
        margin-left: 4.7vw;
    }
}
</style>