<template>
    <div class="dd">
        <div class="de">
            <!-- <img alt="de" src="../assets/de.png" /> -->
        </div>
        <h1>Извините, такой страницы нет, перенаправляем вас на главную страницу</h1>
        <nav>
            <transition>
                <router-link to="/">
                    <button>Главная</button>            
                </router-link>
            </transition>
        </nav>
    </div>
</template>

<script>
export default {
    name: "NotFoundComponent",

}
</script>

<style scoped>

    .dd {
        background-color: #fff;
        height: 100vh;
    }

    .de {
        max-width: 980px;
        background-color: #fff;
    }

    img {
        width: 100%;
        margin: 0 auto;
    }

    h1 {
        text-align: center;
        margin-top: 50px;
        color: #018F3E;
    }

    nav {
        text-align: center;
        font-size: 28px;
    }

    button {
        width: 250px;
        height: 50px;
        background: #018F3E;
        border: #018F3E;
        color: #fff;
    }

</style>