<template>
    <Obz />
    <Head />
    <div class="wr">
        <h1>Вакансии</h1>
        <h1><img src="../assets/wum.jpg" alt="" class="leftimg"> </h1>
        <h2>Медицинская сестра</h2>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue' 
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.wr {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
h2 {
    font-size: 1.2vw;
}
p {
    font-size: 1vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 17vw;
}

@media (max-width: 790px) {

    .wr {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    h2 {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .leftimg {
        width: 40vw;
    }
}
</style>