<template>
    <div class="pri">
        <h1>
            4 причины выбрать нашу стоматологию
        </h1>
        <h2>
            Beautiful Dent - частная стоматологическая клиника для взрослых в Чебоксарах с полным спектром стоматологических услуг. <br><br>
            Мы работаем с 2014 года.
        </h2>
        <div class="row row-cols-1 row-cols-md-4 g-4">
            <div class="col">
                <div class="card">
                    <img alt="" src="../assets/den8.png">
                    <div class="card-body">
                        <h5 class="card-title">Команда профессионалов</h5>
                        <p class="card-text">
                            В нашем штате специалисты высокого уровня, в том числе кандидаты медицинских наук
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <img alt="" src="../assets/den11.png">
                    <div class="card-body">
                        <h5 class="card-title">Безопасность</h5>
                        <p class="card-text">
                            Максимальная безопасность обеспечивается благодаря соблюдению норм стерилизации и хранению медицинских инструментов, использованию одноразовых материалов
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <img alt="" src="../assets/den12.png">
                    <div class="card-body">
                        <h5 class="card-title">Лечение без боли</h5>
                        <p class="card-text">
                            Мы используем современные методы обезболивания с учетом индивидуальных особенностей пациентов
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <img alt="" src="../assets/den13.png">
                    <div class="card-body">
                        <h5 class="card-title">Современные технологии</h5>
                        <p class="card-text">
                            Мы используем эффективные и безопасные методики, которые применяются во всем мире
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.pri {
    width: 82vw;
    margin: 20vh auto;
    height: 65vh;
}
h1 {
    font-size: 2vw;
    color: #018F3E;
    font-weight: bold;
    text-align: center;
    margin-top: 8vh;
}
h2 {
    font-size: 1.2vw;
    color: #018F3E;
    margin-top: 5vh;
}
.row {
    margin-top: 8vh;
}
.card {
    min-height: 45vh;
    --bs-border-color-translucent: #018F3E;
    --bs-card-border-width: 0.2vw;
}
img {
    border-radius: 50%;
    border: 0.2vw solid #018F3E;
    width: 5vw;
    height: 5vw;
    margin-left: 38%;
    margin-top: -5.5vh;
    background-color: #fff;
}
.card-title {
    text-align: center;
    font-size: 1.5vw;
}
.card-text {
    margin-top: 3vh;
    font-size: 1vw;
}


@media (max-width: 790px) {
    .pri {
        height: auto;
        width: 90vw;
        margin: 8vh auto;
    }
    h1 {
        font-size: 27px;
        margin-top: 2vh;
    }
    h2 {
        font-size: 17px;
        margin-top: 5vh;
    }
    img {
        border-radius: 50%;
        border: 3px solid #018F3E;
        width: 19vw;
        height: 19vw;
        margin-left: 39%;
        margin-top: -5.5vh;
    }
    .card {
        margin-top: 5vh;
        min-height: 40vh;
        --bs-card-border-width: 3px;
    }
    .card-title {
        text-align: center;
        font-size: 19px;
    }
    .card-text {
        margin-top: 3vh;
        font-size: 15px;
    }
}
</style>