<template>
    <Obz />
    <Head />
    <div class="wr">
        <h1><img src="../assets/doc6.jpg" alt="" class="leftimg"> Петрова Лариса Владимировна</h1>
        <h2>Врач-стоматолог-терапевт</h2>
        <p>
            Стаж более 5 лет <br>
            <b>Диплом об образовании: </b> <br> №102124 0528615 от 01.07.2014 г. «ЧГУ им. И.Н.Ульянова» «Стоматология» <br>
            <b>Сертификат «Стоматология терапевтическая»:</b> <br> №0121242094569 ФГБОУ ВО «ЧГУ им. И.Н.Ульянова от 02.11.2020 г.
        </p>
        <p>
            Прием в понедельник, пятницу – по записи
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue' 
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.wr {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
h2 {
    font-size: 1.2vw;
}
p {
    font-size: 1vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 17vw;
}

@media (max-width: 790px) {

    .wr {
        width: 80vw;
        height: auto;
        margin-top: 0;
    }
    .leftimg {
        width: 80vw;
        margin-bottom: 7vh;
    }
    h1 {
        font-size: 19px;
    }
    h2 {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }

}
</style>