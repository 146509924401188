<template>
  <div class="modal" id="exampleModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Заполните пожалуйста форму</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form ref="form" @submit.prevent="sendEmail">    
            <input 
              required
              type="text" 
              placeholder="Имя"
              name="user_name"
            >
            <input 
              required
              type="number" 
              placeholder="Телефон"
              name="user_phone"
            >
            <textarea 
              type="text" 
              placeholder="Добавьте сообщение..."
              name="message"
            ></textarea>
            <div class="bt">
              <input 
                class="bts"
                type="submit" 
                value="Отправить"
              >
            </div>
            <p class="zp">Нажимая на кнопку "Отправить", вы даете согласие на обработку <br>
                персональных данных и соглашаетесь с 
                <router-link to="/conf"><button type="button" class="btob" data-bs-dismiss="modal">
                  политикой конфиденциальности</button>
                </router-link>
            </p>
            <div class="vis" 
              v-show="visible"
            >
              <p class="sho">
                Сообщение успешно отправлено, <br>
                скоро мы Вам перезвоним.
              </p> 
              <button 
                type="button" 
                class="btn btn-secondary" 
                data-bs-dismiss="modal"
              >
                Закрыть
              </button>
            </div>  
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: "Modal",
  data: () => ({     
    visible: false,   
  }),
  methods: {
    sendEmail(event) {
      emailjs.sendForm('service_m8cijjj', 'template_uz9n24p', this.$refs.form, 'r9W7BALCELVHJAelr')
        .then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        });
        event.target.reset();
        this.visible = true;
        setTimeout(() => this.visible = false, 10000);
    }
  }
}
</script>

<style scoped>

.modal-content {
  background: #d1fce4;
  min-height: 550px;
  margin-top: 50px;
}

.modal-title {
  color: #333333;
}

input {
  margin-top: 10px;
  border: #333333;
  width: 100%;
  height: 50px;
  padding: 20px;
}

textarea {
  margin-top: 10px;
  border: #333333;
  width: 100%;
  height: 100px;
  padding: 20px;
}

.bt {
  margin-top: 10px;
}

.bts {
  background: orange;
  border: orange;
  color: #333333;
  width: 100%;
  height: 50px;
  font-size: 20px;
  margin-top: 0;
  padding: 0;
}

.bts:hover {
  background: rgb(230, 150, 1);
  border: rgb(230, 150, 1);
}

.vis {
  margin-top: 20px;
  font-size: 17px;
  color: #fff;
}

.btn {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

p {
  font-size: 0.8vw;
  margin-top: 2vh;
  color: #333333;
}

.btob {
  background: none;
  border: none;
  text-decoration: underline;
  color: #333333;
  transition: all 0.4s ease;
}

.btob:hover {
  color: blue;
  transition: all 0.4s ease;
}

.sho {
  font-size: 1.5vw;
  color: green;
  font-weight: bold;
}

@media (max-width: 790px) {

  .zp {
    font-size: 14px;
  }
  .sho {
    font-size: 17px;
    font-weight: bold;
  }

}

</style>