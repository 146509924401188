<template>
    <h1 id="f3">Отзывы пациентов</h1>
    <div class="otz">
        <div class="cen">
            <div class="ott" style="overflow:hidden;position:relative;"><iframe style="width:100%;height:100%;border:1px solid #e6e6e6;border-radius:8px;box-sizing:border-box" src="https://yandex.ru/maps-reviews-widget/132155759262?comments"></iframe><a href="https://yandex.ru/maps/org/beautiful_dent/132155759262/" target="_blank" style="box-sizing:border-box;text-decoration:none;color:#b3b3b3;font-size:10px;font-family:YS Text,sans-serif;padding:0 20px;position:absolute;bottom:8px;width:100%;text-align:center;left:0;overflow:hidden;text-overflow:ellipsis;display:block;max-height:14px;white-space:nowrap;padding:0 16px;box-sizing:border-box">Beautiful dent на карте Чебоксар — Яндекс Карты</a></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.otz {
    display: flex;
    justify-content: center;
    width: 80vw;
    margin: -6vh auto;
    height: 100vh;
}
h1 {
    font-size: 2vw;
    color: #018F3E;
    margin-top: 13vh;
    text-align: center;
    font-weight: bold;
}
.cen {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    border: 0.2vw solid #018F3E;
    border-radius: 1vw;
    width: 67vw;
    height: 130vh;
}
.ott {
    width: 50vw;
    height: 120vh;
}

@media (max-width: 790px) {

    .otz {
        width: 95vw;
    }
    .cen {
        margin-top: 15vw;
        width: 90vw;
        border-radius: 7px;
        height: 125vh;
    }
    h1 {
        font-size: 27px;
        margin-top: 12vw;
    }
    .ott {
        width: 90vw;
    }

}
</style>