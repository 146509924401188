<template>
    <Obz />
    <Head />
    <div class="ortp">
        <h1>
            Ортопедия
        </h1> 
        <ul>
            <li>
                Несъемное протезирование;
            </li>
            <li>
                Протезирование на имплантах, all in 4, all in 6;
            </li>
            <li>
                Съемное протезирование любой сложности;
            </li>
            <li>
                Шинирование при пародонтозе, после снятия брекет-систем;
            </li>
            <li>
                Лечение дисфункции височно-нижнечелюстного сустава.
            </li>
        </ul>
        <b><img src="../assets/im4.jpg" alt="" class="leftimg"> Ортопедическая стоматология – для людей, заботящихся о своем здоровье</b>
        <p>
            Зубные протезы разделяют на съемные и несъемные. Протезирование может быть выполнено с использованием металлокерамики, безметалловой керамики, а также оксида циркония. <br>

Передовые технологии безметалловой керамики становятся все более востребованными, поскольку этот вид протезирования направлен, в основном, на эстетику. Конструкции изготавливаются из высокопрочной керамики без содержания металлов. Это придает конструкциям отличную эстетику, к тому же, керамика не изменяет цвет (не темнеет), на нее не бывает аллергии. Из безметалловой керамики ортопедическая стоматология предлагает множество вариантов конструкций: коронки, виниры, вкладки.
        </p>  
        <br><br><br>
        <p class="cnt"><b>Виды коронок</b></p>      
        <!-- <b><img src="../assets/di3.png" alt="" class="leftimg1"></b>         -->
        
        <b>Металлокерамическая коронка</b>
        <p>
            Внутренняя часть металлокерамической коронки состоит из сплавов металла или металла (титан, золото, платина, палладий, хромоникелевый, золотоплатиновый и др. сплавы). В зависимости от использованных металлов и их сплавов различают металлокерамику на золоте, на кобальто-хромовых сплавах и тому подобное. Благодаря присутствию в сплаве золота удается достичь более естественного оттенка металлокерамических коронок, готовых к установке. <br>

Внутри коронки находится литой каркас толщиной от 0,3 до 0,5 мм На рисунке  вы можете увидеть металлический каркас, изготовленный для мостовидного металлокерамического протеза. <br>

  Впоследствии каркас облицовывается специальной керамической массой, которую изготавливают специально для стоматологических потребностей. Облицовка наносится вручную в несколько слоев. После нанесения отдельного слоя конструкция проходит обжиг в специальной печи, где удерживается температура в 950 градусов. Такой технологией изготовления добиваются прочной химической связи металла и керамики. Облицованный керамикой каркас из металла вы можете увидеть на рисунке - это уже пригодный к установке протез.
        </p>
        <!-- <b><img src="../assets/di4.png" alt="" class="leftimg1"> Культевая вкладка</b> -->
        <p>
            Вкладка под коронку называется культевой вкладкой. С помощью таких вкладок восстанавливается разрушенный под корень зуб (или зуб с очень тонкими стенками), на который планируется поставить коронку. Далее искусственная коронка фиксируется на такую вкладку при помощи специального цемента. <br>

Культевая вкладка имеет корневую и коронковую часть. Корневая часть вкладки выглядит как штифт (эта часть фиксируется в корневом канале). Коронковая часть вкладки уже имеет форму культи зуба, обточенной под коронку. Из-за этого вкладку под коронку и называют «культевой».
        </p>
        <p class="cnt">
            <b>Протезирование на имплантах</b>
        </p>
        <p>
            Протезы на имплантах бывают трех основных типов.
        </p>
        <p>
            <!-- <img src="../assets/di20.jpg" alt="" class="leftimg1"> -->
            <b>Коронки</b></p>
        <p>
            Зубные коронки на имплантах – самый популярный и эстетичный вид протезов, используемых при имплантации зубов. Применяются они повсеместно: в жевательной и фронтальной зоне, для восстановления из расчета «один имплант – одна коронка = один зуб». 
        </p>
        <p>
            <!-- <img src="../assets/di21.jpg" alt="" class="leftimg1"> -->
            <b>Мостовидные протезы</b></p>
        <p>
            С помощью мостовидного протеза, как правило, восстанавливают 3 – 5 отсутствующих подряд зубов. Обычно ставится 2 – 3 импланта, на которых сверху крепятся протезы, имитирующие коронковую часть зуба – они состоят из коронок, которых на 2-4 штуки больше, чем самих имплантов. 
        </p>
        <p>
            <!-- <img src="../assets/di22.jpg" alt="" class="leftimg1"> -->
            <b>Полные протезы</b></p>
        <p>
            Это конструкции, моделирующие всю челюсть целиком. Полные протезы созданы для замещения всех отсутствующих зубов на одной из челюстей. Применяются при наличии у пациента полной отсутствия зубов или зубов, не поддающихся терапевтическому лечению – в этом случае они подлежат удалению с одновременной заменой на импланты.
        </p>
        <p class="cnt">
            <b>Виды съемных протезов</b>
        </p>
        <img src="../assets/di5.png" alt="" class="leftimg1">
        <p>
            Съемные протезы такие пациент может сам снимать и одевать. Они изготавливают при большом количестве отсутствующих зубов, т.е. в тех случаях, когда нет возможности изготовить несъемные протезы. Съемные протезы (в отличие от несъемных) опираются  в первую очередь на десну, но в случае сохранения собственных зубов – часть нагрузки может перераспределяться и на сохранившиеся зубы.
        </p>
        <br>
        <p>
            <b><img src="../assets/di6.png" alt="" class="leftimg1"> Полные съемные зубные протезы</b> такие протезы делают при полном отсутствии зубов на челюсти. Они опираются исключительно на альвеолярные отростки челюстей (грубо говоря – десну), а на верхней челюсти еще и на небо. Фиксация полных съемных протезов (особенно на нижней челюсти) оставляет желать лучшего – именно по причине отсутствия зубов, за которые можно было бы как-то фиксировать протез, например, при помощи кламмеров. Полный съемный протез может быть сделан либо традиционно из пластмассы, либо из нейлона.
        </p>
        <br>
        <p>
            <b><img src="../assets/di7.png" alt="" class="leftimg1"> Частично съемное протезирование</b> означает, что у пациента на челюсти сохранились  один или несколько зубов, которые будут сохранены и использованы для протезирования. Т.е. частичный съемный протез опирается, как правило, не только на десну, но и на сохранившиеся зубы.Такие протезы могут быть выполнены как из пластмассы и нейлона, так и с использованием металлического каркаса (такой протез называется бюгельным).
        <br><br>
        </p>
        <br>
        <p>
            <b><img src="../assets/di8.png" alt="" class="leftimg1"> Бюгельные зубные протезы</b> получили свое название от немецкого слова «Бюгель», что означает «Дуга». Этот вид протезов в отличие от нейлоновых и пластмассовых протезов имеет в своей основе металлический каркас. Благодаря такому каркасу  удалось существенно уменьшить размер пластмассового базиса во рту у пациента, т.е. протез стал более комфортным для ношения, для дикции, а также для приема пищи.
        </p>
        <br><br>
        <p class="cnt">
            <b>Шинирование зубов</b>
        </p>
        <p>
            При некоторых заболеваниях (пародонтите, пародонтозе и т.п.), а также после ношения брекет-систем зубы расшатываются и отклоняются от своего нормального положения. Впоследствии это может привести к потере отдельных зубных единиц, так как нагрузка на жевательную поверхность становится неравномерной. Чтобы сохранить жевательную функцию и эстетичность зубного ряда, подвижные зубы укрепляют с помощью шинирования.
        </p>
        <p>
            Шинирование зубов – это процедура, позволяющая зафиксировать расшатанные зубы и предотвратить их выпадение. 
        </p>
        <p>
            <!-- <img src="../assets/im44.jpg" alt="" class="leftimg1">  -->
            Применение стекловолоконной ленты – эффективный и широко распространенный способ закрепления зубного ряда.
        </p>
        <p>
            Шинирование зубов стекловолокном (стекловолоконной лентой) при пародонтозе позволяет буквально <b> за один визит </b> к врачу <b> избавиться от подвижности зубов. </b>
        </p>
        <p>
            Закрепление стекловолоконной нити осуществляется с внутренней стороны зубов, не видной для окружающих. При этом используется светоотверждающий пломбировочный материал. Такие шины выглядят малозаметно, так как композитный материал имеет оттенок, похожий на естественный цвет эмали пациента.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.ortp {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 40vw;
}
.leftimg1 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 10vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}
.cnt {
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: 790px) {

    .ortp {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    .leftimg1 {
        width: 20vw;
    }

}
</style>