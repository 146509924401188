<template>
  <Obz />
  <Head />
  <div class="about">
    <h1>О клинике</h1>
    <h1>
      <img alt="" src="../assets/di17.png" class="leftimg">
    Стоматология Бьютифул Дент — это здоровье, эстетичность и красота.</h1>
    <h2>Для вас и ваших близких мы готовы предоставить следующий спектр стоматологических услуг (лечения):</h2>
    <ul>
      <li>
        терапевтическое;
      </li>
      <li>
        ортопедическое;
      </li>
      <li>
        ортодонтическое;
      </li>
      <li>
        хирургическое.
      </li>
    </ul>
    <br>
    <h3>Доступность, качество и безопасность - ведущие направления нашей клиники!</h3>
    <h2>
      В стоматологии Бьютифул Дент ведут прием кандидаты медицнских наук, врачи высшей и первой категории, сотрудники кафедр стоматологического факультета Чувашского государственного университета.
    </h2>
    <p>
      ООО «Бьютифул Дент» <br>
        Юридический адрес: 428022 г.Чебоксары, ул. Ю.Гагарина, д. 30, корп. 1, пом. 3. <br>
        Тел.: <a href="tel:+79276675505">8 (927) 667-55-05</a> <br> 
        E-mail: <a class="em" href="mailto:beautiful-dent@mail.ru">beautiful-dent@mail.ru</a> <br><br>
        Часы работы: пн-пт с 09:00 до 19:00, сб, вс по записи с 09:00 до 15:00, четверг – выходной <br>
        График работы специалистов и руководства можно уточнить у администратора.
    </p>
    <p>
      <b>Реквизиты:</b> <br>
      <b>ООО «Бьютифул Дент» </b> <br>
      428022, ЧР, г. Чебоксары <br>
ул. Ю. Гагарина 30 кор 1, пом 3  <br>
р/с   40702810703000138751       <br>                                                
ПРИВОЛЖСКИЙ Ф-Л ПАО «ПРОМСВЯЗЬБАНК» <br>
г.Нижний Новгород <br>
к/с 30101810700000000803 <br>
БИК 042202803 <br>
ИНН  2130134778  /КПП 213001001 <br>
тел : 8(927)667-55-05 <br>
email: beautiful-dent@mail.ru <br>
<br>
Директор Киртаева Анастасия Владиславовна <br>
Основной документ: Устав
    </p>
    <router-link to="/docs">
      <button>Ознакомиться с документами клиники</button>
    </router-link>
    <br><br><br>
            <p>
              Контролирующие органы: <br><br>

        <a href="https://medicin.cap.ru" target="_blank" rel="noopener">
          Министерство здравоохранения Чувашской Республики
        </a>
        <br>
        г. Чебоксары, Президентский бульвар, 17; тел. (8352) 62-35-94<br><br>

        <a href="https://21.rospotrebnadzor.ru" target="_blank" rel="noopener">
          Роспотребнадзор Чувашской Республики
        </a>
        <br>
        г. Чебоксары, Московский пр-кт, 3Д; тел. (8352) 58-17-13<br><br>

        <a href="https://21reg.roszdravnadzor.gov.ru" target="_blank" rel="noopener">
          Управление Росздравнадзора по Чувашской Республике
        </a>
        <br>
        г. Чебоксары, Московский пр-кт, 36; тел. (8352) 58-28-17<br><br>

        <a href="https://medicin.cap.ru/action/activity/licenzirovanie/kontakti" target="_blank" rel="noopener">
          Отдел лицензирования медицинской и фармацевтической деятельности Министерства здравоохранения  Чувашской Республики
        </a>
        <br>
        г. Чебоксары, Ленинградская, 33; тел. (8352) 26-13-39, 26-13-40    <br><br>

        <a href="https://medicin.cap.ru/action/activity/organizaciya-medicinskoj-pomoschi-vzroslomu-nasele/profilaktika/profilaktika-serdechno-sosudistih-zabolevanij/glavnie-vneshtatnie-specialisti-minzdrava-chuvashi" target="_blank" rel="noopener">
          Главный внештатный специалист стоматолог Министерства здравоохранения Чувашской Республики
        </a>
        <br>
        г. Чебоксары, тел. (8352)  58-27-37<br>
    </p>
  </div>
  <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
  name: 'AboutView',
  components: {
    Head,
    Foo,
    Obz,
  }
}
</script>
<style scoped>
.about {
  width: 80vw;
  margin: 7vh auto;
  height: auto;
}
h1 {
  font-size: 2vw;
  text-align: justify;
}
h2 {
  font-size: 1.2vw;
  text-align: justify;
}
h3 {
  font-size: 1.7vw;
  text-align: justify;
}
p {
  font-size: 1vw;
  text-align: justify;
}
.leftimg {
  float:left; /* Выравнивание по левому краю */
  margin: 0.5vw 4vw 1vw 0; /* Отступы вокруг картинки */
  width: 22vw;
}
button {
  width: 25vw;
  height: 6vh;
  margin-top: 3vh;
  background-color: #018F3E;
  border: #018F3E;
  color: white;
  border-radius: 50px;
  font-size: 1vw;
  transition: all 0.4s ease;
}
button:hover {
  transform: scale(1.03);
  transition: all 0.4s ease;
}
a {
  color: #018F3E;
}
p, 
li,
b {
  font-size: 1.1vw;
}

@media (max-width: 790px) {

  .about {
    width: 90vw;
    height: 250%;
    margin-top: 0;
  }
  h1 {
    font-size: 19px;
  }
  h2 {
    font-size: 14px;
    margin-bottom: 5vh;
  }
  h3 {
    font-size: 14px;
    margin-bottom: 5vh;
  }
  p,
  b,
  li {
    font-size: 14px;
    text-align: start;
  }
  .leftimg {
    width: 40vw;
  }
  button {
    width: 90vw;
    font-size: 14px;
  }
}
</style>

