<template>
    <Obz />
    <Head />
    <div class="hyg">
        <h1>
            Профессиональная гигиена полости рта
        </h1> 
        <ul>
            <li>
                Профессиональная чистка зубов;
            </li>
            <li>
                Отбеливание.
            </li>
        </ul>
        <p>
            <img src="../assets/di16.png" alt="" class="leftimg"> Профессиональная гигиена полости рта — это клиническая процедура, которая представляет собой механическую чистку зубов на приеме стоматолога-терапевта или гигиениста.
        </p>
        <p>
            В ходе профессиональной гигиенической чистки снимаются зубные отложения и зубные камни, в том числе в труднодоступных местах, очищается пришеечная область. Таким образом, процедура предотвращает как возникновение кариеса, так и заболевания пародонта. И конечно, устранение налёта в большинстве случаев предупреждает появление неприятного запаха изо рта.
        </p>
        <p>
            Профессиональная гигиена полости рта включает в себя:
        </p>
        <ul>
            <li>
                снятие твердых зубных отложений над и под десной при помощи ультразвука. При необходимости выполняется дополнительная инструментальная обработка ручным способом;
            </li>
            <li>
                снятие пигментированного микробного налёта аппаратным способом при помощи струи воды и специального порошка;
            </li>
            <li>
                полировку зубов с помощью вращающихся щеток и паст;
            </li>
            <li>
                покрытие зубов фтор-лаком или фтор-гелем для укрепления зубной эмали.
            </li>
        </ul>
        <p>
            В результате процедуры поверхность зуба станет идеально гладкой. В процессе и после проведения профессиональной гигиены полости рта вы можете испытывать незначительный дискомфорт, связанный с повышенной чувствительностью эмали и десны. При выявлении пародонтальных карманов специалист направит вас на консультацию и возможное лечение к пародонтологу. Если после проведения процедуры появились боль, кровотечение или отёк десны, необходимо обратиться к своему лечащему врачу.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.hyg {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 17vw;
}
p, 
li,
b {
    font-size: 1.1vw;
}

@media (max-width: 790px) {

    .hyg {
        width: 90vw;
        height: 520%;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    .leftimg {
        width: 90vw;
    }

}
</style>