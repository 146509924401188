<template>
  <div class="home">
    <div class="hh">
      <router-link class="opa" to="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#018F3E" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </router-link>
      <router-link 
        class="rl" 
        :to="{path: '/', hash: '#f4'}"
      >
        <img class="d16" alt="" src="../assets/den16.png">
      </router-link>
      <router-link 
        class="rl" 
        :to="{path: '/', hash: '#f4'}"
      >
        <h5>
          <b>г. Чебоксары </b><br>
          ул. Ю.Гагарина д. 30, корпус 1, <br>
          помещение 3
        </h5>
      </router-link>
      <img class="d16" alt="" src="../assets/den17.png">
      <h5>
        <b>
          Режим работы:
        </b> 
        ПН-ПТ: 9:00-19:00 <br>
        ЧТ: выходной <br>
        СБ-ВС по записи: 9:00-15:00 
      </h5>
      <router-link to="/">
        <img alt="лечение зубов" src="../assets/logo.png">
      </router-link>
      <a 
        href="tel:+79276675505"
      >
        <div class="ttel">
          <h4>
            <img class="d16" alt="" src="../assets/den18.png">
            8 927 667 55 05
          </h4>
        </div>
      </a> 
      <button type="button" class="biz" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Записаться на прием     
      </button>
    </div>
    <Modal />
  </div>
  <Bhead />
</template>

<script>
import Modal from '@/components/Modal.vue'
import Bhead from '@/components/Bhead.vue'

export default {
  name: 'Head',
  components: {
    Modal,
    Bhead,
  }
}
</script>

<style scoped>
.home {
  width: 98%;
  margin: 0 auto;
}
.hh {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  height: 18vh;
  align-items: center;
}
img {
  width: 19vw;
  height: auto;
}
.opa,
.bi {
  display: none;
}
h5 {
  font-size: 0.9vw;
  margin-top: 2vh;
}
.ttel {
  width: 21vw;
  display: flex;
  justify-content: center;
}
h4 {
  font-size: 1.5vw;
  margin-top: 4vh;
  font-weight: bold;
  transition: all 0.4s ease;
}
h4:hover {
  color: #018F3E;
  transition: all 0.4s ease;
}
button {
  width: 15vw;
  height: 6vh;
  margin-top: 3vh;
  background-color: #018F3E;
  border: #018F3E;
  color: white;
  border-radius: 50px;
  font-size: 1vw;
  transition: all 0.4s ease;
}
button:hover {
  transform: scale(1.03);
  transition: all 0.4s ease;
}
.d16 {
  width: 2vw;
}

@media (max-width: 790px) {
  .home {
    height: 20vh;
    width: 90%;
  }
  .hh {
    display: flex;
    justify-content: flex-start;
    align-items: none;
  }
  img {
    width: 60vw;
    height: auto;
    margin-top: -9vh;
  }
  .opa,
  .bi {
    display: inline;
    width: 50px;
    position: absolute;
    margin-top: -5.2vh;
    margin-left: 39vw;
  }
  h5,
  nav,
  button {
    display: none;
  }
  h4 {
    width: 70vw;
    font-size: 22px;
    margin-top: 1vh;
    margin-left: -70vw;
    position: absolute;
  }
  .d16 {
    display: none;
  }
  
}
</style>