<template>
    <Obz />
    <Head />
    <div class="ter">
        <h1>
            Лечение зубов
        </h1>
        <ul>
            <li>
                Диагностика кариеса на ранних стадиях;
            </li>
            <li>
                Лечение кариеса на любой стадии;
            </li>
            <li>
                Лечение пульпита;
            </li>
            <li>
                Лечение периодонтита;
            </li>
            <li>
                Лечение тканей пародонта.
            </li>
        </ul>
        <b>
            <img class="leftimg1" src="../assets/im22.jpg" alt="">
            Кариес и его лечение
        </b>
        <p>
            Кариес - заболевание твёрдых тканей зуба – эмали и дентина, - которое проявляется изменением цвета зуба, в дальнейшем размягчением (деминерализацией) и разрушением его структуры и образованием дефекта в виде полости. Основной причиной возникновения и развития кариеса являются бактерии того самого мягкого зубного налёта, который необходимо удалять во время ежедневной чистки зубов, о чём всегда твердят врачи-стоматологи своим пациентам.
        </p>
        <p>
            Кариес — очень распространённое заболевание. Примерно 95-98 % взрослых имеют запломбированные зубы.
        </p>
        <p>
            При рассмотрении механизмов возникновения кариеса зуба обращает на себя внимание многообразие различных факторов, взаимодействие которых и обуславливает возникновение очага деминерализации: микроорганизмы полости рта, характер питания (количество углеводов), режим питания, количество и качество слюноотделения, сдвиги в функциональном состоянии организма, количество фтора, поступающего в организм, влияние окружающей среды и т. д. Однако основные факторы для возникновения кариеса следующие: кариесвосприимчивость зубной поверхности, кариесогенные бактерии, ферментируемые углеводы и время.
        </p>
        <p>
            Частота поражения кариесом отдельных зубов:
        </p>
        <ul>
            <li>
                Моляры нижней челюсти поражаются чаще моляров верхней челюсти;
            </li>
            <li>
                Среди моляров наиболее часто поражаются 6-ки, затем 7-ки, в последнюю очередь 8-ки;
            </li>
            <li>
                Среди премоляров чаще поражаются зубы верхней челюсти.
            </li>
        </ul>
        <p>
            Локализация кариеса в %-ом соотношении:
        </p>
        <ul>
            <li>
                35% кариеса развивается на жевательной поверхности;
            </li>
            <li>
                25% - на апроксимальных (контактных) поверхностях;
            </li>
            <li>
                20% - кариес на центральных зубах;
            </li>
            <li>
                20% - пришеечный кариес.
            </li>
        </ul>
        <p>
            По глубине распространения различают 4 стадии развития кариеса:
        </p>
        <ul>
            <li>
                Кариозное пятно;
            </li>
            <li>
                Поверхностный кариес;
            </li>
            <li>
                Средний кариес;
            </li>
            <li>
                Глубокий кариес.
            </li>
        </ul>
        <div>
            <img src="../assets/di1.png" alt="">
        </div>
        <b>
            Кариес в стадии пятна
        </b>
        <p>
            Цвет пятна может быть от мелового до желто-коричневого. Белое пятно-более острое течение процесса. Эмаль на месте пятна остаётся гладкой, но не блестит как на остальных участках зуба. В этой стадии кариес ещё может быть обратим. При хорошей гигиене полости рта и проведении ремтерапии (насыщении тканей зуба ионами кальция и фтора), кариес в этой стадии можно «заморозить».
        </p>
        <b>
            Поверхностный кариес
        </b>
        <p>
            На втором этапе разрушается целостность эмали, поверхность зуба в месте поражения становится шероховатой. Пациенты предъявляют жалобы на боль от кислого, сладкого, холодного и могут без труда указать причинный зуб. Боль проходит сразу после снятия раздражителя.
        </p>
        <b>
            Средний кариес
        </b>
        <p>
            Кариозный процесс развивается как в эмали, так и в дентине. Зуб на раздражения не реагирует.
        </p>
        <b>
            Глубокий кариес
        </b>
        <p>
            Зуб реагирует на холодное и горячее, боли при приёме пищи (из-за механического воздействия пищи на дно кариозной полости). В процесс вовлечены эмаль, дентин зуба и имеются изменения в сосудисто-нервном пучке (пульпе) зуба. <br>
    Степень глубины поражения определяется не h, а h1. Когда h больше h1 – кариес считается глубоким.
        </p>
        <b>
            Лечение кариеса
        </b>
        <p>
            Лечение кариеса – основная задача, с которой приходится сталкиваться стоматологам по всему миру. Связано это с тем, что кариес – самое распространённое заболевание человека. Какими бы твёрдыми не казались наши зубы, они очень легко поддаются разрушению, а если быть точными, то гниению – именно так «кариес» переводится с латинского языка. В общем же, кариес – это патологический процесс, при котором происходит деминерализация и размягчение твёрдых тканей, в результате чего образуется полость. И чем дольше вы откладываете лечение кариеса, тем больше эта полость становится. <br>
    Лечением кариеса необходимо заниматься сразу же, как Вы заметили его признаки, иначе он разовьётся и вылечить зубы будет сложнее, особенно если возникнут осложнения в виде пульпита и периодонтита. <br>
    Лечение кариеса зубов зависит от стадии. <br><br>

    Самым сложным является лечение глубоко кариеса, т.к. эта стадия развития кариеса связана с проникновением инфекции и развитием воспаления в глубоких тканях, где находится множество нервных окончаний и кровеносных сосудов. Лечение кариеса на стадии глубокого развития требует полного удаления мягких пораженных тканей из пульповой камеры и корневых каналов, после чего производится тщательная очистка образовавшейся полости, и только после этого герметичное пломбирование. <br><br>

    Что касается лечения поверхностного и среднего кариеса, то оно также включает в себя препарирование (удаление пораженных тканей), с последующим пломбированием. Завершающим этапом в лечении кариеса является полировка пломбы и восстановление правильного прикуса. <br><br>

    Только лечение кариеса на стадии кариозного пятна не требует вмешательства бормашины. Здесь лечение зубов может ограничиться реминерализующей терапией, т.е. восстановление минерального баланса зубов с помощью профессиональных паст, фторсодержащих лаков и различных растворов, насыщенных необходимыми минералами.
        </p>
        <b>
            Профилактика
        </b>
        <p>
            Основными мерами профилактики кариеса являются:
        </p>
        <ul>
            <li>
                регулярный уход за полостью рта;
            </li>
            <li>
                снижение потребления сахара;
            </li>
            <li>
                использование фторсодержащих зубных паст;
            </li>
            <li>
                соблюдение правильной технологии чистки зубов;
            </li>
            <li>
                употребление ксилита не менее 3 раз в день после еды в виде содержащих ксилит жевательных резинок;
            </li>
            <li>
                профилактический осмотр у стоматолога не реже 1 раза в полгода.
            </li>
        </ul>
        <p>
            К сожалению, подавляющее большинство людей обращается к стоматологу, когда зубы начинают болеть, а это значит, что кариес уже развился и необходимо оперативное лечение зубов, когда требуется и анестезия, и бормашина, и пломбировочный материал. В то время как профилактический поход в стоматологию раз в полгода мог бы свести лечение кариеса к реминерализующей терапии.
        </p>
        <p>
        <b>Пульпит</b> - это воспаление пульпы зуба. В подавляющем большинстве случаев пульпит развивается в результате попадания инфекции из кариозной полости в пульпу зуба. Воспаление протекает только в внутри зуба (в пульпе) и каких-либо воспалительных изменений в нижележащих тканях (периодонте, костной ткани) при пульпите не наблюдается. <br><br>

    Острая форма пульпита  – признаки пульпита при этой форме сводятся к появлению острых, приступообразных болей (особенно в ночное время). Боли носят самопроизвольный характер, возникают спонтанно без каких-либо раздражителей.  Однако в «безболевые» промежутки боль может провоцироваться термическими раздражителями (преимущественно холодной водой, но возможно и от горячей).Причем после устранения раздражителя боль не проходит еще порядка 10-15 минут. Последнее обстоятельство позволяет отличать пульпитные боли от болей при кариесе, при котором боли прекращаются сразу же после прекращения действия раздражителя. <br><br>

    Часто пациенты не могут правильно указать больной зуб, т.к. боль может   распространяться по нервам на всю челюсть. Острота боли увеличивается по ходу перехода воспаления от серозного – к гнойному. При развитии гнойного процесса  боли приобретают пульсирующий, стреляющий, рвущий характер, а безболевые промежутки сокращаются вплоть до полного их исчезновения. <br><br>

    Хроническая форма пульпита  – при этой форме основным симптомом проявления пульпита является также Боль, но которая будет носить уже не такой выраженный характер как при остром пульпите. Боль  может сопровождаться ноющими, периодически возникающими болями. Очень редко болевые ощущения могут совсем отсутствовать. Хронический пульпит может протекать с периодическими обострениями. В период таких обострений симптоматика преимущественно будет соответствовать острому пульпиту. 
        </p>
        <p>
            <b>Периодонтит</b> – симптомы этого заболевания прежде всего связаны с возникновением очага гнойной инфекции  в области верхушки корня зуба. Термин «периодонтит» произошел от слова «периодонт». Периодонт – это связочный аппарат зуба, при помощи которого зуб прикрепляется к костной ткани. <br><br>

    Симптомы острого периодонтита  – пациенты обычно жалуются на ноющие боли в конкретном зубе. Легкое постукивание либо накусывание на зуб вызывает усиление болевых ощущений. При переходе от серозного воспаления к гнойному – ноющие боли могут превратиться в пульсирующие рвущие боли, лишь с очень редкими безболевыми промежутками. Может нарастать подвижность причинного зуба. Также может появится, так называемый флюс. <br><br>

    Может нарушаться общее самочувствие: появляется слабость, температура, нарушение сна. Из-за острой боли при жевании пациенты часто отказываются от приема пищи. При такой форме периодонтита очень часто может наблюдаться припухлость мягких тканей лица в проекции причинного зуба. <br><br>

    Симптомы хронического периодонтита  – эта форма периодонтита очень часто протекает бессимптомно. В некоторых случаях может быть болезненным накусывание на зуб, а также  постукивание по нему. Боль в этом случае умеренная, не сильная. На десне в проекции причинного зуба может существовать свищевое отверстие, из которого будет выделяться скудное гнойное отделяемое.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.ter {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}
img {
    width: 50vw;
}
.leftimg1 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 40vw;
}

@media (max-width: 790px) {

    .ter {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    img {
        width: 90vw;
    }

}
</style>