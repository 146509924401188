<template>
    <Obz />
    <Head />
    <div class="pri">
        <h1>Цены</h1>
        <div class="pp">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr class="table-primary">
                        <th scope="col"></th>
                        <th scope="col">Услуги</th>
                        <th scope="col">Цены, руб</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"></th>
                        <td> <b> Терапевтическое лечение </b></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">1</th>
                        <td>
                        Консультация врача-стоматолога, врача-стоматолога-терапевта
                        </td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Лечение кариеса зубов </td>
                        <td>от 1 800</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <!-- <td colspan="2">Larry the Bird</td> -->
                        <td>Лечение каналов зубов </td>
                        <td>от 2 500</td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>Фторирование одной челюсти </td>
                        <td>600</td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>Снятие гиперчувствительности 1 зуба </td>
                        <td>80</td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>Профессиональная гигиена полости рта </td>
                        <td>от 3 000</td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>Чистка зубов (ультразвук, щетки, пасты) </td>
                        <td>от 1 500</td>
                    </tr>
                    <tr>
                        <th scope="row">8</th>
                        <td>Чистка зубов (снятие пигментированного налета) системой air-flow </td>
                        <td>от 2 000</td>
                    </tr>
                    <tr>
                        <th scope="row">9</th>
                        <td>Наложение губного ретрактора </td>
                        <td>от 500</td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>Виниры из светового материала </td>
                        <td>от 3 500</td>
                    </tr>
                    <tr>
                        <th scope="row">11</th>
                        <td>Художественная реставрация зубов  </td>
                        <td>от 2 870</td>
                    </tr>
                    <tr>
                        <th scope="row">12</th>
                        <td>SPA-отбеливание </td>
                        <td>3 500</td>
                    </tr>
                    <tr>
                        <th scope="row">13</th>
                        <td>Установка зубного украшения  </td>
                        <td>2 000</td>
                    </tr>
                    <tr>
                        <th scope="row">14</th>
                        <td>Анестезия </td>
                        <td>от 260</td>
                    </tr>
                    <tr>
                        <th scope="row"></th>
                        <td> <b> Ортопедическое лечение </b></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">15</th>
                        <td>Консультация врача-стоматолога-ортопеда </td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <th scope="row">16</th>
                        <td>Косметическая пластинка  </td>
                        <td>от 4 000</td>
                    </tr>
                    <tr>
                        <th scope="row">17</th>
                        <td>Съемный протез (при частичном отсутствии зубов)  </td>
                        <td>от 7 000</td>
                    </tr>
                    <tr>
                        <th scope="row">18</th>
                        <td>Съемный протез (при полном отсутствии зубов)   </td>
                        <td>от 14 500</td>
                    </tr>
                    <tr>
                        <th scope="row">19</th>
                        <td>Металлокерамическая коронка  </td>
                        <td>от 7 500</td>
                    </tr>
                    <tr>
                        <th scope="row">20</th>
                        <td>Безметалловая керамическая коронка  </td>
                        <td>от 14 000</td>
                    </tr>
                    <tr>
                        <th scope="row">21</th>
                        <td>Культевая штифтовая вкладка  </td>
                        <td>от 2 800</td>
                    </tr>
                    <tr>
                        <th scope="row">22</th>
                        <td>Коронки на имплантах  </td>
                        <td>от 25 500</td>
                    </tr>
                    <tr>
                        <th scope="row">23</th>
                        <td>Протезирование на имплантах (all in 4, all in 6) </td>
                        <td>от 86 000</td>
                    </tr>
                    <tr>
                        <th scope="row">24</th>
                        <td>Снятие слепков </td>
                        <td>от 550</td>
                    </tr>
                    <tr>
                        <th scope="row"></th>
                        <td><b>Ортодонтическое лечение</b> </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">25</th>
                        <td>Консультация врача-ортодонта </td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <th scope="row">26</th>
                        <td>Брекет система керамическая, лигируемая   </td>
                        <td>от 28 000</td>
                    </tr>
                    <tr>
                        <th scope="row">27</th>
                        <td>Брекет система керамическая, самолигирующая </td>
                        <td>от 38 000</td>
                    </tr>
                    <tr>
                        <th scope="row">28</th>
                        <td>Брекет система металлическая, лигируемая (работа по установке)  </td>
                        <td>от 18 000</td>
                    </tr>
                    <tr>
                        <th scope="row">29</th>
                        <td>Ортодонтические съемные конструкции (в зависимости от выбранной конструкции)  </td>
                        <td>от 3 000</td>
                    </tr>
                    <tr>
                        <th scope="row">30</th>
                        <td>Смена дуги </td>
                        <td>1 800</td>
                    </tr>
                    <tr>
                        <th scope="row">31</th>
                        <td>Снятие брекер-системы  </td>
                        <td>от 6 000</td>
                    </tr>
                    <tr>
                        <th scope="row"></th>
                        <td><b>Хирургическое лечение</b> </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">32</th>
                        <td>Консультация врача-стоматолога-хирурга </td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <th scope="row">33</th>
                        <td>Удаление зуба </td>
                        <td>от 1300</td>
                    </tr>
                    <tr>
                        <th scope="row">34</th>
                        <td>Имплантация зубов  </td>
                        <td>от 26 500</td>
                    </tr>
                    <tr>
                        <th scope="row">35</th>
                        <td>Имплантация all in 4 </td>
                        <td>160 000</td>
                    </tr>
                    <tr>
                        <th scope="row">36</th>
                        <td>Имплантация all in 6 </td>
                        <td>180 000</td>
                    </tr>
                    <tr>
                        <th scope="row">37</th>
                        <td>Имплантация минивинта </td>
                        <td>8 900</td>
                    </tr>
                    <tr>
                        <th scope="row">38</th>
                        <td>Синус-лифтинг  </td>
                        <td>от 35 000</td>
                    </tr>
                    <tr>
                        <th scope="row">39</th>
                        <td>Остеопластика </td>
                        <td>от 30 000</td>
                    </tr>
                    <tr>
                        <th scope="row">40</th>
                        <td>Анестезия  </td>
                        <td>от 260</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <p>
            В этом месте может размещаться пояснительный текст к прайсу.
        </p> -->
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue' 
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'


export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.pri {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
p, 
tr,
td,
th {
    font-size: 1.1vw;
}

@media (max-width: 790px) {

    .pri {
        width: 90vw;
        height: 350%;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    tr,
    td,
    th {
        font-size: 14px;
    }
}
</style>