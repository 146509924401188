<template>
  <Obz />
  <Head />
  <Glav />
  <Pri />
  <Servis />
  <Stock />
  <Cif />
  <Im />
  <Doc />
  <Zap />
  <Otz />
  <Contacts />
  <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Glav from '@/components/Glav.vue'
import Pri from '@/components/Pri.vue'
import Im from '@/components/Im.vue'
import Servis from '@/components/Servis.vue'
import Stock from '@/components/Stock.vue'
import Doc from '@/components/Doc.vue'
import Zap from '@/components/Zap.vue'
import Otz from '@/components/Otz.vue'
import Contacts from '@/components/Contacts.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'
import Cif from '@/components/Cif.vue'

export default {
  name: 'HomeView',
  components: {
    Head,
    Glav,
    Pri,
    Im,
    Servis,
    Stock,
    Doc,
    Zap,
    Otz,
    Contacts,
    Foo,
    Obz,
    Cif,
  }
}
</script>

<style scoped>

</style>
