<template>
    <Obz />
    <Head />
    <div class="impl">
        <h1>
            Хирургическая стоматология
        </h1> 
        <ul>
            <li>
                Удаление зубов любой сложности;
            </li>
            <li>
                Имплантация зубов;
            </li>
            <li>
                Имплантация all in 4, all in 6;
            </li>
            <li>
                Имплантация ортодонтических винтов;
            </li>
            <li>
                Остеопластика;
            </li>
            <li>
                Синус-лифтинг.
            </li>
        </ul>
        <p><b>Имплантация зубов</b> - это установка части конструкции, которая помогает восстановить утраченный зуб.</p>
        <p>
            По сути имплант является искусственным корнем зуба. Он представляет собой винт, который вкручивается в кость челюсти — на место, где когда-то «сидел» родной зуб. Сверху к импланту прикручивается переходник: абатмент. А уже на нем — с помощью цемента или специального винта — фиксируется искусственная коронка. Это та часть зуба, которую мы видим во рту.
        </p>
        <p>
            По итогу пациент получает полноценный искусственный зуб, который выглядит и функционирует как настоящий.
        </p>
        <p>
           <img src="../assets/di10.png" alt="" class="leftimg1"> Имплантами можно замещать один, несколько или даже все отсутствующие зубы. В идеале винты устанавливают на место каждого удаленного зуба. Но когда дефекты протяженные, чаще прибегают к компромиссным варианту: в челюсть внедряют всего несколько имплантов-опор, которые затем покрывают несъемным протезом по типу мостовидного.
        </p>
        <b>При полном отсутствии зубов на импланты устанавливают съемный протез.</b> <br><br>
        <b>Из чего делают импланты?</b>
        <p>
            Чаще — из сплавов титана. Титан не взаимодействует с тканями организма и подходит подавляющему числу пациентов. Из него изготавливают многие эндопротезы (то есть протезы, устанавливаемые внутрь тела) — в частности искусственные суставы и сосудистые стенты. <br><br>

Если у пациента выявляется аллергия на титан, ему рекомендуют установку циркониевых имплантов. Ни одного случая аллергической реакции на диоксид циркония в мире пока зарегистрировано не было.
        </p>
        <b>Есть ли противопоказания к имплантации?</b>
        <p>
            Есть. К ним относятся:
        </p>
        <ul>
            <li>
                Онкологические заболевания на этапе активного лечения;
            </li>
            <li>
                Болезни минерального обмена (из-за которых нарушаются процессы регенерации кости);
            </li>
            <li>
                Проведение лучевой терапии на область головы и шеи в прошлом;
            </li>
            <li>
                Болезни крови (лейкозы, эритроцитозы, нарушение свертываемости крови);
            </li>
            <li>
                Нелеченные инфекции в полости рта, пародонтит в стадии обострения;
            </li>
            <li>
                Прием бисфосфонатов (препаратов для лечения остеопороза);
            </li>
            <li>
                Острые инфекции, период обострения хронических заболеваний;
            </li>
            <li>
                Беременность;
            </li>
            <li>
                Возраст до 20-22 лет (импланты не устанавливают до тех пор, пока не завершится формирование лицевого скелета).
            </li>
        </ul>
        <p>
            <img src="../assets/di11.png" alt="" class="leftimg1"> Такие заболевания, как сахарный диабет, артериальная гипертония, болезни щитовидной железы, противопоказаниями к имплантации не являются. Главное, чтобы на момент проведения операции пациент получал необходимое ему лечение и хорошо себя чувствовал.
        </p>
        <b>Через какое время после удаления зуба можно ставить имплант?</b>
        <p>
            Удалить зуб и установить в его лунку имплант можно за один визит. Правда, при условии, что в оперируемой области нет воспалений и костной ткани хватает для закрепления импланта. В противном случае имплантацию проводятся спустя 2-3 месяца после удаления зуба.
        </p>
        <b>Какие обследования нужно пройти перед имплантацией?</b>
        <p>
            По степени сложности имплантация не отличается от операции по удалению зуба. Поэтому никаких углубленных обследований не требует. <br>

Единственное обязательное исследование, которое просит пройти хирург: компьютерная томография. Она позволяет получить 3D-рентгеновский снимок, по которому оценивается состояние кости челюсти. <br>

Если пациент входит в группу риска по сахарному диабету (у него избыточный вес), врач также может дополнительно назначить анализ на уровень глюкозы в крови. <br>

Имплантация откладывается при плохом самочувствии и наличии каких-либо жалоб на здоровье. В этом случае пациент направляется на обследование к терапевту или другому узкопрофильному специалисту.
        </p>
        <b><img src="../assets/di12.png" alt="" class="leftimg2"> Сколько времени займет все лечение: от первого визита к хирургу до получения готового зуба?</b>
        <p>
            Есть разные варианты имплантации. В общем случае — когда речь идет об установке одного-двух имплантов — все лечение занимает около полугода. <br>

Сначала проводится первичная консультация, делаются рентгеновские, КТ снимки и подбираются подходящие модели имплантов. Затем выполняется операция по их установке — один имплант устанавливается за 15-20 минут. Дальше наступает период приживления имплантов. Он длится в среднем 3-5 месяцев. После того, как имплант окончательно срастется с костью, на него устанавливают формирователь десны. Это специальная структура, которая формирует красивый контур мягких тканей вокруг будущего зуба. Через 2-3 недели снимается слепок и изготавливается коронка. <br><br>

Если пациенту требуется операция по наращиванию кости, она иногда совмещается с установкой импланта. Но чаще проводится отдельным этапом — в этом случае сроки лечения увеличиваются еще на 3-4 месяца (сначала наращивается кость, потом проводится имплантация). <br><br>

<br>

При большом количестве отсутствующих зубов (больше 3 рядом стоящих) возможно применение особых методик имплантации, которые позволяют покрывать импланты протезом практически сразу — на 3-4 день (строго по показаниям).
        </p>
        <b>Какие могут быть осложнения у имплантации?</b>
        <p>
            Как и при любых других хирургических вмешательствах, возможны кровотечение или инфицирование раны. Но риски этих осложнений невелики — они встречаются не более, чем у 0,5% пациентов. <br>

Также у имплантации есть одно уникальное осложнение — периимплантит. Это воспаление кости вокруг импланта, которое приводит к его расшатыванию и потере (или, как говорят пациенты, к «отторжению»). <br>

По данным научных исследований, периимплантит развивается примерно у 3% пациентов в течение 10 лет после их установки.
        </p>
    </div>
    <Foo />
</template>

<script>
import Head from '@/components/Head.vue'
import Foo from '@/components/Foo.vue'
import Obz from '@/components/Obz.vue'

export default {
    components: {
        Head,
        Foo,
        Obz,
    }
}
</script>

<style scoped>
.impl {
    width: 80vw;
    margin: 7vh auto;
    height: auto;
}
h1 {
    font-size: 2vw;
}
.leftimg1 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 30vw;
}
.leftimg2 {
    float:left; /* Выравнивание по левому краю */
    margin: 0.5vw 2vw 1vw 0; /* Отступы вокруг картинки */
    width: 30vw;
}
p, 
li,
b {
    font-size: 1.1vw;
    text-align: justify;
}

@media (max-width: 790px) {

    .impl {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }
    h1 {
        font-size: 19px;
    }
    p, 
    li,
    b {
        font-size: 14px;
    }
    .leftimg1 {
        width: 50vw;
    }
    .leftimg2 {
        width: 50vw;
    }

}
</style>