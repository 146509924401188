import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import GalleryView from '../views/GalleryView.vue'
import Im2View from '../views/Im2View.vue'
import Im3View from '../views/Im3View.vue'
import Im4View from '../views/Im4View.vue'
import Im5View from '../views/Im5View.vue'
import Im6View from '../views/Im6View.vue'
import Im7View from '../views/Im7View.vue'
import Im8View from '../views/Im8View.vue'
import Im9View from '../views/Im9View.vue'
import TherapyView from '../views/TherapyView.vue'
import OrthopedicsView from '../views/OrthopedicsView.vue'
import OrthodonticsView from '../views/OrthodonticsView.vue'
import ImplantationView from '../views/ImplantationView.vue'
import AestheticView from '../views/AestheticView.vue'
import HygieneView from '../views/HygieneView.vue'
import PriceView from '../views/PriceView.vue'
import KavView from '../views/KavView.vue'
import CdfView from '../views/CdfView.vue'
import PlvView from '../views/PlvView.vue'
import HsvView from '../views/HsvView.vue'
import BdeView from '../views/BdeView.vue'
import AjmView from '../views/AjmView.vue'
import VacanciesView from '../views/VacanciesView.vue'
import DocsView from '../views/DocsView.vue'
import ConfView from '../views/ConfView.vue'
import RulesView from '../views/RulesView.vue'
import PayView from '../views/PayView.vue'
import GuaranteeView from '../views/GuaranteeView.vue'
import DeductionView from '../views/DeductionView.vue'
import MenuView from '../views/MenuView.vue'
import VncsView from '../views/VncsView.vue'
import AlignersView from '../views/AlignersView.vue'
import RecomView from '../views/RecomView.vue'
import ListView from '../views/ListView.vue'

import NotFoundComponent from '../views/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/im2',
    name: 'im2',
    component: Im2View
  },
  {
    path: '/im3',
    name: 'im3',
    component: Im3View
  },
  {
    path: '/im4',
    name: 'im4',
    component: Im4View
  },
  {
    path: '/im5',
    name: 'im5',
    component: Im5View
  },
  {
    path: '/im6',
    name: 'im6',
    component: Im6View
  },
  {
    path: '/im7',
    name: 'im7',
    component: Im7View
  },
  {
    path: '/im8',
    name: 'im8',
    component: Im8View
  },
  {
    path: '/im9',
    name: 'im9',
    component: Im9View
  },
  {
    path: '/therapy',
    name: 'therapy',
    component: TherapyView
  },
  {
    path: '/orthopedics',
    name: 'orthopedics',
    component: OrthopedicsView
  },
  {
    path: '/orthodontics',
    name: 'orthodontics',
    component: OrthodonticsView
  },
  {
    path: '/implantation',
    name: 'implantation',
    component: ImplantationView
  },
  {
    path: '/aesthetic',
    name: 'aesthetic',
    component: AestheticView
  },
  {
    path: '/hygiene',
    name: 'hygiene',
    component: HygieneView
  },
  {
    path: '/price',
    name: 'price',
    component: PriceView
  },
  {
    path: '/kav',
    name: 'kav',
    component: KavView
  },
  {
    path: '/cdf',
    name: 'cdf',
    component: CdfView
  },
  {
    path: '/plv',
    name: 'plv',
    component: PlvView
  },
  {
    path: '/hsv',
    name: 'hsv',
    component: HsvView
  },
  {
    path: '/bde',
    name: 'bde',
    component: BdeView
  },
  {
    path: '/ajm',
    name: 'ajm',
    component: AjmView
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: VacanciesView
  },
  {
    path: '/docs',
    name: 'docs',
    component: DocsView
  },
  {
    path: '/conf',
    name: 'conf',
    component: ConfView
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesView
  },
  {
    path: '/payservises',
    name: 'payservises',
    component: PayView
  },
  {
    path: '/guarantee',
    name: 'guarantee',
    component: GuaranteeView
  },
  {
    path: '/deduction',
    name: 'deduction',
    component: DeductionView
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/vncs',
    name: 'vncs',
    component: VncsView
  },
  {
    path: '/aligners',
    name: 'aligners',
    component: AlignersView
  },
  {
    path: '/recom',
    name: 'recom',
    component: RecomView
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
  
  {
    path: '/:pathMatch(.*)*', 
    name: 'notfound',
    component: NotFoundComponent
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { 
      top: 0, 
      behavior: 'smooth',
    }
  },
})

export default router
