<template>
    <h1 id="f2">Наши врачи</h1>
    <div class="doc">
        <div class="line">
            <div class="row row-cols-1 row-cols-md-6 g-6">
                <div class="col">
                    <router-link class="lin" to="/kav">
                        <div class="card h-100">
                            <img src="../assets/doc2.png" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Киртаева Анастасия Владиславовна</h5>
                            <p class="card-text">
                                врач-стоматолог-ортопед <br>
                                врач-стоматолог-терапевт <br>
                                кандидат медицинских наук
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link class="lin" to="/cdf">
                        <div class="card h-100">
                            <img src="../assets/cham.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title2">Чамеев Дмитрий Федорович</h5>
                            <p class="card-text">
                                врач-стоматолог-хирург
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link class="lin" to="/plv">
                        <div class="card h-100">
                            <img src="../assets/doc6.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Петрова <br> Лариса Владимировна</h5>
                            <p class="card-text">
                                врач-стоматолог-терапевт
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link class="lin" to="/hsv">
                        <div class="card h-100">
                            <img src="../assets/doc3.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Хрисанов Станислав Владимирович</h5>
                            <p class="card-text">
                                врач-стоматолог-ортопед
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link class="lin" to="/bde">
                        <div class="card h-100">
                            <img src="../assets/doc5.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Буторова <br> Дарья Евгеньевна</h5>
                            <p class="card-text">
                                врач-ортодонт
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- <div class="col">
                    <router-link class="lin" to="/ajm">
                        <div class="card h-100">
                            <img src="../assets/doc4.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Аль-Мобарак Ясмина Махеровна</h5>
                            <p class="card-text">
                                врач-стоматолог
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div> -->
            </div>
        </div>

        <div class="caru">
            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <router-link class="lin" to="/kav">
                        <div class="card h-100">
                            <img src="../assets/doc2.png" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Киртаева Анастасия Владиславовна</h5>
                            <p class="card-text">
                                врач-стоматолог-ортопед <br>
                                врач-стоматолог-терапевт <br>
                                кандидат медицинских наук
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="carousel-item">
                    <router-link class="lin" to="/cdf">
                        <div class="card h-100">
                            <img src="../assets/man.png" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title2">Чамеев Дмитрий Федорович</h5>
                            <p class="card-text">
                                врач-стоматолог-хирург
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="carousel-item">
                    <router-link class="lin" to="/plv">
                        <div class="card h-100">
                            <img src="../assets/doc6.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Петрова Лариса Владимировна</h5>
                            <p class="card-text">
                                врач-стоматолог-терапевт
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="carousel-item">
                    <router-link class="lin" to="/hsv">
                        <div class="card h-100">
                            <img src="../assets/doc3.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Хрисанов Станислав Владимирович</h5>
                            <p class="card-text">
                                врач-стоматолог-ортопед
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="carousel-item">
                    <router-link class="lin" to="/bde">
                        <div class="card h-100">
                            <img src="../assets/doc5.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Буторова <br> Дарья Евгеньевна</h5>
                            <p class="card-text">
                                врач-ортодонт
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="carousel-item">
                     <router-link class="lin" to="/ajm">
                        <div class="card h-100">
                            <img src="../assets/doc4.jpg" class="card-img-top" alt="">
                            <div class="card-body">
                            <h5 class="card-title">Аль-Мобарак Ясмина Махеровна</h5>
                            <p class="card-text">
                                врач-стоматолог
                            </p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
            </div>
        </div>
        <div class="btt">
            <router-link to="/recom">
                <button>
                    Рекомендации после лечения, установки брекетов, протезирования и имплантации
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Doc',
    components: {
        
    }
}
</script>

<style scoped>
.doc {
    width: 92vw;
    height: 75vh;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
    color: #018F3E;
    text-align: center;
    font-weight: bold;
    margin-top: 3vh;
}
.row {
    max-width: 90%;
    margin: 0 auto;
    --bs-gutter-x: 0.5rem;
}
.card-title {
    color: black;
    text-decoration: none;
    font-size: 1.2vw;
}
.card-title2 {
    color: black;
    text-decoration: none;
    font-size: 1.2vw;
    margin-top: 4vh;
}
.card-text {
    color: black;
    text-decoration: none;
    font-size: 0.65vw;
}
.card {
    transition: all 0.1s ease;
}
.card:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 5px 5px rgba(185, 185, 185, 0.7);
    transition: all 0.1s ease;
}
.caru {
    display: none;
}
.btt {
    display: flex;
    justify-content: center;
}
button {
    width: 50vw;
    height: 6vh;
    margin-top: 7vh;
    background-color: #018F3E;
    border: #018F3E;
    color: white;
    border-radius: 50px;
    font-size: 1vw;
    transition: all 0.4s ease;
}
button:hover {
    transform: scale(1.03);
    transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .doc {
        height: 500%;
    }
    h1 {
        font-size: 27px;
        margin-left: 5vw;
        margin-top: -3vh;
    }
    .row {
        max-width: 100%;
    }
    .col {
        margin-top: 2vh;
    }
    .card-title,
    .card-title2 {
        font-size: 19px;
    }
    .card-text {
        font-size: 15px;
    }
    .line {
        display: none;
    }
    .caru {
        display: block;
        height: auto;
        margin-top: -5vh;
    }
    .carousel {
        height: auto;
    }
    .carousel-control-prev,
    .carousel-control-next {
        margin-top: 40vh;
        width: 12vw;
    }
    .btt {
        margin-top: 4vh;
    }
    button {
        width: 90vw;
        height: 8vh;
        margin-top: 0vh;
        background-color: #018F3E;
        border: #018F3E;
        color: white;
        border-radius: 50px;
        font-size: 14px;
        transition: all 0.4s ease;
    }
}

</style>